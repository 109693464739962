import React, { useEffect, useRef, useState } from "react";
import ReactSelect from "../Other/ReactSelect";

function FormInput(props) {
  const [archivo, setArchivo] = useState(null);
  const [error, setError] = useState();

  const switchForm = useRef();

  const inputFileRef = useRef();
  const fileNameRef = useRef();

  useEffect(() => {
    if (archivo !== null) props.onChange(archivo);
  }, [archivo]);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const expresiones = {
    usuario: /^[a-zA-Z0-9_-]{4,16}$/, // Letras, numeros, guion y guion_bajo
    nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
    password: /^.{4,12}$/, // 4 a 12 digitos.
    correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    telefono: /^\d{7,14}$/, // 7 a 14 numeros.
  };

  const errorMsg = {
    password: "La contraseña debe ser de 4 a 12 digitos.",
    correo: "El formato del correo electrónico no es válido.",
  };

  const validate = (e) => {
    let valid;
    if (props.type === "email") {
      valid = expresiones.correo.test(e.target.value);
      !valid ? setError(errorMsg.correo) : setError();
    } else if (props.type === "password") {
      valid = expresiones.password.test(e.target.value);
      !valid ? setError(errorMsg.password) : setError();
    }
  };

  switch (props.type) {
    case "text":
    case "email":
    case "date":
    case "number":
    case "password":
      return (
        <div
          className={
            props.long == true ? "form-group form-group-large" : "form-group"
          }
        >
          <label>{props.label}</label>
          <div className={props.required ? "is-required" : ""}>
            {props.icon ? (
              props.icon
            ) : props.required ? (
              <i
                className={`material-icons required-symbol ${
                  error ? "red-icon" : "input-symbol"
                }`}
              >
                
              </i>
            ) : null}

            <input
              type={props.type}
              className={error ? "is-invalid" : null}
              placeholder={props.placeholder && props.placeholder}
              autoComplete="off"
              onChange={(e) => props.onChange(e)}
              onBlur={validate}
              value={props.value}
              name={props.name}
              style={props.style}
              hidden={props.hidden}
              defaultValue={props.defaultValue}
            />
          </div>
          <p className="is-invalid error-info">{error}</p>
        </div>
      );

    case "dropdown":
      return (
        <div className="form-group">
          <label>{props.label}</label>
          <div className={props.required ? "is-required select-required" : ""}>
            {props.required ? (
              <i
                className={`material-icons required-symbol  ${
                  error ? "red-icon" : "input-symbol"
                }`}
              >
                
              </i>
            ) : (
              props.icon
            )}
            <select
              onChange={(e) => props.onChange(e)}
              className={error ? "is-invalid" : ""}
              value={props.value}
              style={props.style}
              defaultValue={props.defaultValue}
              hidden={props.hidden}
            >
              <option hidden value="">
                Seleccione
              </option>
              {props.options?.map((option, i) => (
                <option key={i} value={option[props.optionIdName]}>
                  {option[props.optionValueName]}
                </option>
              ))}
            </select>
          </div>
          <p className="is-invalid error-info">{error}</p>
        </div>
      );
    case "dropdown-multiple":
      return (
        <div
          className={props.long ? "form-group form-group-large" : "form-group"}
        >
          <label>{props.label}</label>
          <ReactSelect
            onChange={(val) => props.onChange(val.map((c) => c.value))}
            name={props.name}
            value={props.options.filter((c) => props.value?.includes(c.value))}
            options={props.options}
            isMulti={true}
            isValid={error == undefined}
            isRequired={props.required}
          />
          {error && <p className="is-invalid error-info">{error}</p>}
        </div>
      );

    case "radio":
      return (
        <div className="form-group">
          <label>{props.label}</label>
          <div>
            {props.options?.map((option) => (
              <div key={option}>
                <input
                  type="radio"
                  label={option}
                  value={option}
                  onChange={(value) => props.onChange(value)}
                  checked={props.value === option}
                  {...props}
                />
                <label>{option}</label>
              </div>
            ))}
          </div>
          <p className="is-invalid error-info">{error}</p>
        </div>
      );

    case "checkbox":
      return (
        <div className="form-group">
          <>
            <input
              type="checkbox"
              onChange={(e) => props.onChange(e.target.checked)}
              checked={props.value}
              {...props}
            />
            <label>{props.label}</label>
          </>
          <p className="is-invalid error-info">{error}</p>
        </div>
      );

    case "switch":
      return (
        <div>
          <div className="flex-container">
            <label>{props.label}</label>
            <div className="switch-container">
              <input
                type="checkbox"
                ref={switchForm}
                className="checkbox-switch"
                checked={props.value}
                readOnly
              />
              <label
                className="fake-switch"
                onClick={() => {
                  switchForm.current.checked = !switchForm.current.checked;
                  props.onChange(switchForm.current.checked);
                }}
              >
                <span>{props.positiveOption}</span>
                <span>{props.negativeOption}</span>
              </label>
            </div>
          </div>
          <p className="is-invalid error-info">{error}</p>
        </div>
      );

    case "simple-switch":
      return (
        <div>
          <div className="flex-container">
            <label>{props.label}</label>
            <div className="switch-container">
              <input
                type="checkbox"
                ref={switchForm}
                className="checkbox-switch"
                checked={props.value}
                readOnly
              />
              <label
                className="fake-switch fake-switch-unlabeled"
                onClick={() => {
                  switchForm.current.checked = !switchForm.current.checked;
                  props.onChange(switchForm.current.checked);
                }}
              ></label>
            </div>
          </div>
          <p className="is-invalid error-info">{error}</p>
        </div>
      );

    case "file":
      const handleChangeFile = (event) => {
        event.preventDefault();
        let file = event.target.files[0];
        setArchivo(file);
        fileNameRef.current.value = file.name;
      };

      return (
        <div>
          <label>{props.label}</label>
          <div className="file-input-container">
            <button
              className="btn btn-file"
              type="button"
              onClick={() => inputFileRef.current.click()}
            >
              <i className="Material-Icons-Outlined"></i>
            </button>
            <input
              readOnly
              className="file-input-name"
              ref={fileNameRef}
              onClick={() => inputFileRef.current.click()}
              placeholder={props.placeholder}
            ></input>
            <input
              ref={inputFileRef}
              type="file"
              id="file"
              aria-label="Subir archivo"
              style={{
                visibility: "hidden",
                position: "absolute",
                pointerEvents: "none",
              }}
              onChange={(e) => {
                props.directChange ? props.onChange(e) : handleChangeFile(e);
              }}
            />
          </div>
          <p className="is-invalid error-info">{error}</p>
        </div>
      );

    case "pop-apps":
      return (
        <div>
          <div className="flex-container">
            <img className="card-app-img-container" src={props.image} />
          </div>
        </div>
      );

    default:
      return null;
  }
}

export { FormInput };
