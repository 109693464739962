import React, { useEffect, useRef, useState } from "react";
import logo from "../../IMG/LogoCenfotec2.svg";
import logoAmplio from "../../IMG/LogoCenfotec.svg";
import noImg from "../../IMG/no-person-img.png";
import MenuItem from "./MenuItem";
import SideSubmenu from "./SideSubmenu";

function Sidebar({ isOpened, toggleMenu, usuario }) {
  const [roles, setRoles] = useState(["anonymous"]);

  const subMenuButton = useRef();
  const [isSubmenuActive, setSubmenuActive] = useState(false);

  useEffect(() => {
    setRoles(extractRoles);
  }, []);

  const extractRoles = () => {
    let token = localStorage.getItem("token");

    if (token) {
      let tokenDecoded = decodeURIComponent(
        window
          .atob(token.split(".")[1].replace("-", "+").replace("_", "/"))
          .split("")
          .map((c) => `%${("00" + c.charCodeAt(0).toString(16)).slice(-2)}`)
          .join("")
      );
      let jsonToken = JSON.parse(tokenDecoded);

      if (!Array.isArray(jsonToken.roles)) return [jsonToken.roles];
      else return jsonToken.roles;
    }
  };

  const buscarCoincidencia = (arr1, arr2) => {
    for (var i = 0; i < arr1.length; i++) {
      if (arr2.indexOf(arr1[i]) != -1) return true;
    }
    return;
  };

  const menuButtons = [
    {
      route: "/",
      icon: <i className="Material-Icons-Outlined"></i>,
      name: "Dashboard",
      roles: ["administrador(a) del sistema"],
    },
    {
      route: "/cargaDatos",
      icon: <i className="Material-Icons-Outlined"></i>,
      name: "Carga de datos",
      roles: ["administrador(a) del sistema"],
    },
    {
      route: "/asientos",
      icon: <i className="Material-Icons-Outlined"></i>,
      name: "Asientos",
      roles: ["administrador(a) del sistema"],
    },
    {
      route: "/usuarios",
      icon: <i className="Material-Icons-Outlined"></i>,
      name: "Usuarios",
      roles: ["administrador(a) del sistema"],
    },
  ];

  const menuButtonsOnMobile = [
    { route: "", icon: <i className="material-icons"></i>, name: "Ayuda" },
    {
      route: "configuraciones",
      icon: <i className="Material-Icons-Outlined"></i>,
      name: "Configuración",
    },
    {
      route: "",
      icon: <i className="Material-Icons-Outlined"></i>,
      name: "Cerrar sesión",
    },
  ];

  const displaySubMenu = () => {
    setSubmenuActive(!isSubmenuActive);
  };

  const getClassButtonMenu = ({ isActive }) => {
    return isActive
      ? "btn main-menu-button is-selected"
      : "btn main-menu-button";
  };

  return (
    <>
      <div
        id="fake-vertical-menu"
        className={
          isOpened ? "is-hidden-on-mobile" : "is-closed is-hidden-on-mobile"
        }
      ></div>
      <nav id="vertical-menu" className={!isOpened ? "is-closed" : null}>
        {isOpened ? (
          <div className="logo-container">
            <img src={logoAmplio} alt="Logo empresa" />
          </div>
        ) : (
          <div
            className="logo-container"
            style={{ height: "60px", width: "25px" }}
          >
            <img src={logo} alt="Logo empresa" />
          </div>
        )}
        <div className="options-container">
          <section className="main-options">
            <button className="btn user-btn bold is-visible-on-mobile">
              <img
                src={noImg}
                className="circular-container-sm"
                alt="Foto de perfil logo iglesia"
              />
              <span>{usuario.FullName}</span>
            </button>

            <div className="is-hidden-on-mobile">
              <ul>
                {menuButtons?.map((btn, i) =>
                  buscarCoincidencia(roles, btn.roles) ? (
                    <li key={i}>
                      <MenuItem
                        route={btn.route}
                        class={getClassButtonMenu}
                        icon={btn.icon}
                        name={btn.name}
                      />
                    </li>
                  ) : null
                )}
              </ul>
            </div>

            <div className="is-visible-on-mobile">
              <ul>
                {menuButtons?.map((btn, i) =>
                  buscarCoincidencia(roles, btn.roles) ? (
                    <li key={i} onClick={toggleMenu}>
                      <MenuItem
                        route={btn.route}
                        class={getClassButtonMenu}
                        icon={btn.icon}
                        name={btn.name}
                      />
                    </li>
                  ) : null
                )}
              </ul>
            </div>

            <div className="is-visible-on-mobile">
              <ul>
                {menuButtonsOnMobile.map((btn, i) => (
                  <li onClick={toggleMenu} key={i}>
                    <MenuItem
                      route={btn.route}
                      class={getClassButtonMenu}
                      icon={btn.icon}
                      name={btn.name}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </section>
          <SideSubmenu
            isSubmenuActive={isSubmenuActive}
            displaySubMenu={displaySubMenu}
          ></SideSubmenu>
        </div>
      </nav>
    </>
  );
}

export default Sidebar;
