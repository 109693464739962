import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";

export const cambiarEstadoCurso = createAsyncThunk(
  "cursos/actualizarCurso",
  async (item, { rejectWithValue }) => {
    try {
      await WebApi.api().put("Cursos/" + item.CursoId, {
        ...item,
        EstadoId: item.EstadoId === 1 ? 2 : 1,
      });
      return { success: true, message: "Cambios realizados", show: true };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  isLoading: false,
};

export const cursosSlice = createSlice({
  name: "cursos",
  reducers: {},
  initialState,
  extraReducers: {
    [cambiarEstadoCurso.pending]: (state, action) => {
      state.isLoading = true;
    },
    [cambiarEstadoCurso.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export default cursosSlice.reducer;
