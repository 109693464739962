import jwtDecode from "jwt-decode";
import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ component: Component, allowedRoles }) => {
  const [roles, setRoles] = useState([]);

  function hasJWT() {
    let flag = false;
    //check user has JWT token
    let token = localStorage.getItem("token");

    if (token && token !== undefined) {
      let decoded = jwtDecode(token);
      flag = true;
      if (roles?.length === 0) {
        if (typeof decoded.roles === "string") {
          let arr = [];
          arr.push(decoded.roles);
          setRoles(arr);
        } else {
          setRoles(decoded.roles);
        }
      }
    } else {
      flag = false;
    }
    return flag;
  }

  return hasJWT() ? (
    Array.isArray(roles) ? (
      roles?.find((x) => allowedRoles?.includes(x)) ? (
        <Outlet />
      ) : (
        <Navigate to="/required" state={roles} />
      )
    ) : allowedRoles.includes(roles) ? (
      <Outlet />
    ) : (
      <Navigate to="/required" state={roles} />
    )
  ) : (
    <Navigate to="/login" />
  );
};
export default ProtectedRoute;
