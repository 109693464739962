import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import configuraciones from "./reducers/configuracionesSlice";
import genericos from "./reducers/genericosSlice";
import logs from "./reducers/logsSlice";
import roles from "./reducers/rolesSlice";
import usuarios from "./reducers/usuariosSlice";
import plantillas from "./reducers/plantillasSlice";
import profesoresPrecios from "./reducers/profesoresPreciosSlice";
import importaciones from "./reducers/importacionesSlice";
import asientos from "./reducers/asientosSlice";
import centrosCostos from "./reducers/centrosCostosSlice";
import nivelesAcademicos from "./reducers/nivelesAcademicosSlice";
import tiposCuentas from "./reducers/tiposCuentasSlice";
import cursos from "./reducers/cursosSlice";


export default configureStore({
  reducer: {
    asientos,
    genericos,
    configuraciones,
    usuarios,
    roles,
    logs,
    plantillas,
    profesoresPrecios,
    importaciones,
    centrosCostos,
    nivelesAcademicos,
    tiposCuentas,
    cursos
  },
  middleware:(getDefaultMiddleware)=>getDefaultMiddleware({
    serializableCheck:false
  })
});
