import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";


export const obtenerTiposCuentas = createAsyncThunk(
    "tiposCuentas/obtenerTiposCuentas",
    async (n, { rejectWithValue }) => {
      try {
        const res = await WebApi.api().get("TiposCuentas");
        return res.data.Result;
      } catch (err) {
        return rejectWithValue(err.response);
      }
    }
  );
  export const obtenerCuentasContables = createAsyncThunk(
    "tiposCuentas/obtenerCuentasContables",
    async (n, { rejectWithValue }) => {
      try {
        const res = await WebApi.api().get("CuentasContables");
        return res.data.Result;
      } catch (err) {
        return rejectWithValue(err.response);
      }
    }
  );

  export const obtenerTipoCuentaById = createAsyncThunk(
    "tiposCuentas/obtenerTipoCuentaById",
    async (id, { rejectWithValue }) => {
      try {
        const res = await WebApi.api().get("TiposCuentas/"+id);
        return res.data.Result;
      } catch (err) {
        return rejectWithValue(err.response);
      }
    }
  );
  
  export const actualizarTipoCuenta = createAsyncThunk(
    "tiposCuentas/actualizarTipoCuenta",
    async (item, { rejectWithValue }) => {
      try {
        delete item["ModificadoPor"];
        delete item["FechaModificacion"];
        await WebApi.api().put("TiposCuentas/"+item.TipoId,item);
        return { success: true, message: "Cambios realizados", show: true };
      } catch (err) {
        return rejectWithValue(err.response);
      }
    }
  );
  
  const initialState = {
    tiposCuentas: [],
    cuentasContables:[],
    tipoCuenta:{},
    isLoading:true,
  };
  
  export const tiposCuentasSlice = createSlice({
    name: "tiposCuentas",
    reducers: {},
    initialState,
    extraReducers: {
      [obtenerTiposCuentas.pending]: (state, action) => {
        state.isLoading=true;
      },
      [obtenerTiposCuentas.fulfilled]: (state, action) => {
        state.tiposCuentas = action.payload;
        state.isLoading=false;
      },
      [obtenerCuentasContables.pending]: (state, action) => {
        state.isLoading=true;
      },
      [obtenerCuentasContables.fulfilled]: (state, action) => {
        state.cuentasContables = action.payload;
        state.isLoading=false;
      },
      [obtenerTipoCuentaById.fulfilled]: (state, action) => {
        state.tipoCuenta = action.payload;
      },
    },
  });
  
  export default tiposCuentasSlice.reducer;
  