import React, { useEffect, useRef, useState } from "react";

import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import ContextActions from "../Components/DataBooks/ContextActions";
import Options from "../Components/DataBooks/Options";
import PageActions from "../Components/DataBooks/PageActions";
import Form from "../Components/Form/Form";
import Card from "../Components/Other/Card";
import Modal from "../Components/Other/Modal";
import ModalRemove from "../Components/Other/ModalRemove";
import usePagination from "../Hooks/usePagination";
import NoResults from "../IMG/NoResults.svg";
import { obtenerRoles } from "../Redux/reducers/rolesSlice";
import {
  actualizarUsuario,
  agregarUsuario,
  eliminarUsuario,
  obtenerUsuarios,
} from "../Redux/reducers/usuariosSlice";

export default function ListUsuarios() {
  const formRef = useRef();

  const [showModal, setShowModal] = useState();
  const [removingItem, setRemovingItem] = useState(null);
  const [dataEdit, setDataEdit] = useState(null);
  const [data, setData] = useState();
  const [registrosPorPagina, setRegistrosPorPagina] = useState(5);
  const dispatch = useDispatch();
  const { usuarios, isLoading } = useSelector((state) => state.usuarios);
  const { roles } = useSelector((state) => state.roles);

  const modelForm = {
    Email: {
      label: "Correo",
      type: "text",
      placeholder: "Correo",
      defaultValue: "",
      required: true,
      rules: {
        required: true,
      },
    },
    FullName: {
      label: "Nombre",
      type: "text",
      placeholder: "Nombre completo",
      defaultValue: "",
      required: true,
      long: false,
      rules: {
        required: true,
      },
    },
    IsSystemUser: {
      label: "Es usuario del sistema ?",
      type: "checkbox",
      defaultValue: false,
    },
  };

  const [
    totalPages,
    startPageIndex,
    endPageIndex,
    currentPageIndex, //ESLINT
    displayPage,
  ] = usePagination(registrosPorPagina, usuarios.length);

  useEffect(() => {
    setData(usuarios?.slice(startPageIndex, endPageIndex));
    setRemovingItem(null);
  }, [startPageIndex, endPageIndex, usuarios]);

  useEffect(() => {
    cargarDatos();
  }, []);

  const cargarDatos = (nombre = "") => {
    dispatch(obtenerUsuarios({ name: nombre }));
    dispatch(obtenerRoles());
  };

  const eliminar = async (data) => {
    await dispatch(
      eliminarUsuario({
        ...data,
        UserId: data.UserId,
        IsActive: data.IsActive == 1 ? false : true,
      })
    );
    cargarDatos();
    return true;
  };

  const editModal = (item) => {
    setDataEdit(item);
    setShowModal(true);
  };

  const onSubmit = (data) => {
    if (data.UserId) {
      dispatch(actualizarUsuario({ ...data, IsActive: true }))
        .unwrap()
        .then((res) => {
          cargarDatos();
          setShowModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      //agrega
      dispatch(agregarUsuario({ ...data }))
        .unwrap()
        .then((res) => {
          cargarDatos();
          setShowModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const renderTable = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Código de usuario</th>
            <th>Nombre Completo</th>
            <th>Email</th>
            <th>Estado</th>
            <th>Usuario del sistema</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => (
            <tr key={item.UserId}>
              <td>{item.UserId}</td>
              <td>{item.FullName}</td>
              <td>{item.Email}</td>
              <td>{item.IsActive == 1 ? "Activo" : "Inactivo"}</td>
              <td>{item.IsSystemUser == 1 ? "Si" : "No"}</td>
              <td>
                <Options
                  actions={[
                    {
                      onclick: () => editModal(item),
                      icon: <i className="Material-Icons-Outlined"></i>,
                      text: "editar",
                    },
                    {
                      onclick: () => setRemovingItem(item),
                      icon: <i className="Material-Icons-Outlined"></i>,
                      text: item.IsActive == 1 ? "Desactivar" : "Activar",
                    },
                  ]}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <Modal isVisible={showModal} title={dataEdit ? "Editar" : "Agregar"}>
        <Form
          ref={formRef}
          dynamicForm={modelForm}
          onSubmit={onSubmit}
          onRecoil={() => {
            setShowModal(false);
            setDataEdit({});
          }}
          dataToEdit={dataEdit}
          isEditing={dataEdit!==null}
        />
      </Modal>

      <ContextActions
        btnNew="Nuevo usuario"
        onClick={() => {
          editModal(null);
        }}
        searchFunction={cargarDatos}
        logs="Users"
        showAll={false}
      />

      <ModalRemove
        title={
          removingItem?.IsActive == 1 ? "Desactivar usuario" : "Activar usuario"
        }
        message={`¿Es seguro que desea ${
          removingItem?.IsActive == 1 ? "desactivar" : "activar"
        } el usuario de ${removingItem?.FullName}?`}
        onRecoil={() => setRemovingItem(null)}
        onSubmit={() => eliminar(removingItem)}
        removingItem={removingItem}
        recordName={removingItem?.Username}
      />

      <motion.main
        id="main"
        className="register"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Card
          classN="card container-col-whole-space"
          isLoading={isLoading}
          type={"table"}
        >
          <h2 className="dark-text table-section-name">Usuarios</h2>
          {!data?.length > 0 ? (
            <div className="no-results-container">
              <img src={NoResults} alt="Sin resultados"></img>
              <h2 className="grey-text">No se encontraron registros</h2>
            </div>
          ) : (
            <div className="table-container">{renderTable()}</div>
          )}
          <PageActions
            count={totalPages} //usuarios.PageCount
            onChange={(value) => displayPage(value)}
            changeRegistersCount={(value) => setRegistrosPorPagina(value)}
            registersCount={registrosPorPagina} //usuarios.RowsCount
          />
        </Card>
      </motion.main>
    </>
  );
}
