import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import useComponentVisible from "../../Hooks/useComponentVisible";

function ContextActions({
  searchFunction,
  searchPlaceholder,
  pathToNew,
  onClick,
  btnNew,
  setWayOfDisplayData,
  wayOfDisplayData,
  showAll = true,
  customItem,
  defaultSearch = true,
  logs,
}) {
  const [filtersVisibility, setFiltersVisibility] = useState(false);
  const [newFilterVisibility, setNewFilterVisibility] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const navigate = useNavigate();

  return (
    <>
      <div className="flex-container context-actions-container">
        {showAll && (
          <>
            <button
              onClick={() => setFiltersVisibility(!filtersVisibility)}
              className="btn registers-btn-action icon-btn-on-mobile"
              id="btn-filters"
            >
              <i className="material-icons"></i>Filtros
            </button>
            <button
              className="btn registers-btn-action"
              onClick={() => setNewFilterVisibility(!newFilterVisibility)}
            >
              <i className="material-icons"></i>Nuevo filtro
            </button>
          </>
        )}
        {defaultSearch && (
          <input
            className="registers-input"
            type="search"
            placeholder={searchPlaceholder ? searchPlaceholder : "Búsqueda..."}
            onChange={(e) => searchFunction(e.target.value)}
          />
        )}

        {logs && (
          <button
            className="btn registers-btn-action"
            onClick={() => {
              navigate(`./logs/${logs}`);
            }}
          >
            Cambios hechos
          </button>
        )}
        {customItem && customItem}
        {showAll && (
          <>
            <button
              ref={ref}
              className="btn registers-btn-action icon-btn-on-mobile"
              id="btn-change-view"
              onClick={() => setIsComponentVisible(!isComponentVisible)}
            >
              {wayOfDisplayData == 2 ? (
                <i className="material-icons"></i>
              ) : (
                <i className="Material-Icons-Outlined"></i>
              )}
              <i className="material-icons"></i>
            </button>
            <div
              className={
                isComponentVisible
                  ? "view-options-container"
                  : "view-options-container is-hidden"
              }
            >
              <button
                className="btn dark-text"
                onClick={() => setWayOfDisplayData(2)}
              >
                <i className="material-icons"></i> Lista
              </button>
              <button
                className="btn dark-text"
                onClick={() => setWayOfDisplayData(1)}
              >
                <i className="Material-Icons-Outlined"></i> Mosaico
              </button>
            </div>
          </>
        )}
        {btnNew && (
          <button
            className="btn registers-btn-action"
            onClick={() => {
              if (pathToNew) navigate(pathToNew);
              else onClick();
            }}
          >
            <i className="material-icons"></i>
            {btnNew}
          </button>
        )}
      </div>
    </>
  );
}

export default ContextActions;
