import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";


export const ImportarAsiento = createAsyncThunk(
  "importacionDatos/ImportarAsiento",
  async (item, { rejectWithValue }) => {
    try {
      delete item.dynamicFieldArray;
      const res = await WebApi.api().post(
        "DocumentosAsientosContables/GenerarAsientoDelMes",
        item
      );
      return { success: 1, title:"¡Bien!",message: "Asientos creados", show: true };
    } catch (err) {
      return { success: 2, title:"Algo anda mal",message: "Intente de nuevo más tarde", show: true };
    }
  }
);

export const obtenerAsientos = createAsyncThunk(
  "importacionDatos/obtenerAsientos",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        "DocumentosAsientosContables/GetListado",item
      );
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const obtenerAsientosGeneral = createAsyncThunk(
  "importacionDatos/obtenerAsientosGeneral",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        "DocumentosAsientosContables/GetMeses",item
      );
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const obtenerDetalleAsiento = createAsyncThunk(
  "importacionDatos/obtenerDetalleAsiento",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        "DocumentosAsientosContables/DetalleAsiento",
        item
      );
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const obtenerFallosAsiento = createAsyncThunk(
  "importacionDatos/obtenerFallosAsiento",
  async (item, { rejectWithValue }) => {
    try {
      delete item.dynamicFieldArray;
      const res = await WebApi.api().post(
        "DocumentosAsientosContables/Validar",
        item
      );
      return res.data.Result;
    } catch (err) {
      console.log(err);
    }
  }
);

export const aplicarAsiento = createAsyncThunk(
  "importacionDatos/aplicarAsiento",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        "DocumentosAsientosContables/Aplicar",
        item
      );
      return { success: 1,title:"¡Bien!", message: "Asiento aplicado", show: true, res };
    } catch (err) {
      return { success: 2,title:"Algo anda mal", message: "Intente de nuevo más tarde", show: true };
    }
  }
);

export const obtenerAsientosExcel = createAsyncThunk(
  "importacionDatos/obtenerAsientosExcel",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api()
        .post("PLANTILLADEASIENTOSEXCEL", item, {responseType:"blob"})
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `Asientos ${+new Date()}.xlsx`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const obtenerAsientosExcelAnexos = createAsyncThunk(
  "importacionDatos/obtenerAsientosExcelAnexos",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api()
        .post("DOCUMENTOSASIENTOSCONTABLESANEXOSEXCEL", item,{responseType:"blob"})
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `Anexos ${+new Date()}.xlsx`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const obtenerAsientosExcelResumen = createAsyncThunk(
  "importacionDatos/obtenerAsientosExcelResumen",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api()
        .post("ASIENTODELMESRESUMENEXCEL", item,{responseType:"blob"})
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `Resumen ${+new Date()}.xlsx`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);


export const obtenerAsientosExcelPorId = createAsyncThunk(
  "importacionDatos/obtenerAsientosExcelPorId",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api()
        .post("PLANTILLADEASIENTOSPORASIENTOIDEXCEL", item,{responseType:"blob"})
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `Detalle Asiento ${+new Date()}.xlsx`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);


const initialState = {
  isLoading: true,
  asientos: [],
  detalleAsiento: [],
  fallosAsiento: null,
  meses:[],
};

export const asientosSlice = createSlice({
  name: "asientos",
  reducers: {},
  initialState,
  extraReducers: {
    [obtenerAsientos.pending]: (state, action) => {
      state.isLoading = true;
    },
    [obtenerAsientos.fulfilled]: (state, action) => {
      state.asientos = action.payload;
      state.isLoading = false;
    },
    [obtenerDetalleAsiento.pending]: (state, action) => {
      state.isLoading = true;
    },
    [obtenerDetalleAsiento.fulfilled]: (state, action) => {
      state.detalleAsiento = action.payload;
      state.isLoading = false;
    },
    [obtenerFallosAsiento.fulfilled]: (state, action) => {
      state.fallosAsiento = action.payload;
    },
    [obtenerAsientosGeneral.pending]: (state, action) => {
      state.isLoading = true;
    },
    [obtenerAsientosGeneral.fulfilled]:(state,action)=>{
      state.meses = action.payload;
      state.isLoading = false;
    },
    [obtenerFallosAsiento.pending]: (state, action) => {
      state.fallosAsiento = null;
    },
  },
});

export default asientosSlice.reducer;
