import React, { useEffect } from "react";
import FormButtons from "../Form/FormButtons";
import Modal from "./Modal";
import DeleteImg from "../../IMG/DeleteQuestion.svg";
import Success from "../../IMG/Success.svg";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

function ModalRemove({
  onRecoil,
  onSubmit,
  removingItem,
  title,
  recordName,
  message = null,
  image = null,
  useSuccessMessage = true,
}) {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(removingItem);

  useEffect(() => {
    setLoading(false);
    if (removingItem == null && useSuccessMessage) {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setItem(removingItem);
      }, 2000);
    } else {
      setItem(removingItem);
    }
  }, [removingItem]);

  const deleteItem = () => {
    setLoading(true);
    onSubmit();
  };

  return (
    <Modal
      classN="modal-delete"
      title={title}
      isVisible={item !== null}
      bottomItems={
        <div className="bottom-on-delete">
          <FormButtons
            onRecoil={() => setItem(null)}
            onProgress={deleteItem}
            loading={loading}
          />
        </div>
      }
    >
      <div className="modal-info">
        <AnimatePresence>
          {success == false ? (
            <>
              <motion.img
                initial={{ scale: 0.2 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.1 }}
                transition={{ duration: 0.5 }}
                src={image ? image : DeleteImg}
                alt="Ilustración advertencia"
                className="warning-icon"
              ></motion.img>
              <p>
                {message
                  ? message
                  : `¿Es seguro que desea eliminar el registro de ${recordName}?`}
              </p>
            </>
          ) : (
            <motion.div
              initial={{ scale: 0.2 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.1 }}
              transition={{ duration: 1 }}
            >
              <img
                src={Success}
                alt="Ilustración operación exitosa"
                className="warning-icon"
              ></img>
              <p>Operación realizada exitosamente.</p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Modal>
  );
}

export default ModalRemove;
