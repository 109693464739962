import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";


export const obtenerNivelesAcademicos = createAsyncThunk(
    "nivelesAcademicos/obtenerNivelesAcademicos",
    async (n, { rejectWithValue }) => {
      try {
        const res = await WebApi.api().get("NivelesAcademicos");
        return res.data.Result;
      } catch (err) {
        return rejectWithValue(err.response);
      }
    }
  );

  export const obtenerNivelAcademicoById = createAsyncThunk(
    "nivelesAcademicos/obtenerNivelAcademicoById",
    async (id, { rejectWithValue }) => {
      try {
        const res = await WebApi.api().get("NivelesAcademicos/"+id);
        return res.data.Result;
      } catch (err) {
        return rejectWithValue(err.response);
      }
    }
  );
  
  export const actualizarNivelAcademico = createAsyncThunk(
    "nivelesAcademicos/actualizarNivelAcademico",
    async (item, { rejectWithValue }) => {
      try {
        delete item["ModificadoPor"];
        delete item["FechaModificacion"];
        delete item["CursosEstudiantesNivelAcademicoId"];
        delete item["DocumentosAsientosContablesDetalleNivelAcademicoId"];
        delete item["FacturasServiciosNivelAcademicoId"];
        await WebApi.api().put("NivelesAcademicos/"+item.NivelAcademicoId,item);
        return { success: true, message: "Cambios realizados", show: true };
      } catch (err) {
        return rejectWithValue(err.response);
      }
    }
  );
  
  const initialState = {
    nivelesAcademicos: [],
    nivelAcademico:{},
    isLoading:true,
  };
  
  export const nivelesAcademicosSlice = createSlice({
    name: "nivelesAcademicos",
    reducers: {},
    initialState,
    extraReducers: {
      [obtenerNivelesAcademicos.pending]: (state, action) => {
        state.isLoading=true;
      },
      [obtenerNivelesAcademicos.fulfilled]: (state, action) => {
        state.nivelesAcademicos = action.payload;
        state.isLoading=false;
      },
      [obtenerNivelAcademicoById.fulfilled]: (state, action) => {
        state.nivelAcademico = action.payload;
      },
    },
  });
  
  export default nivelesAcademicosSlice.reducer;
  