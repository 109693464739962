import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";


export const obtenerCentrosCostos = createAsyncThunk(
    "centrosCostos/obtenerCentrosCostos",
    async (n, { rejectWithValue }) => {
      try {
        const res = await WebApi.api().get("CentrosCostos");
        return res.data.Result;
      } catch (err) {
        return rejectWithValue(err.response);
      }
    }
  );

  export const obtenerCentroCostoById = createAsyncThunk(
    "centrosCostos/obtenerCentroCostoById",
    async (id, { rejectWithValue }) => {
      try {
        const res = await WebApi.api().get("CentrosCostos/"+id);
        return res.data.Result;
      } catch (err) {
        return rejectWithValue(err.response);
      }
    }
  );
  
  export const actualizarCentroCosto = createAsyncThunk(
    "centrosCostos/actualizarCentroCosto",
    async (item, { rejectWithValue }) => {
      try {
        delete item["ModificadoPor"];
        delete item["FechaModificacion"];
        let id=item.Id;
        delete item["Id"]
        const res = await WebApi.api().put("CentrosCostos/"+id,item);
        return { success: true, message: "Cambios realizados", show: true };
      } catch (err) {
        return rejectWithValue(err.response);
      }
    }
  );
  
  
  export const almacenarCentroCosto= createAsyncThunk(
    "centrosCostos/almacenarCentroCosto",
    async (item, { rejectWithValue }) => {
      try {
        let obj={...item,EstadoId:1,EsTextoPlano:false}
        const res = await WebApi.api().post("CentrosCostos",obj);
        return { success: true, message: "Centro Costo almacenado", show: true };
      } catch (err) {
          return { success: false, message: err, show: true };
      }
    }
  );
  
  
  
  const initialState = {
    centrosCostos: [],
    centroCosto:{},
    isLoading:true,
  };
  
  export const centrosCostosSlice = createSlice({
    name: "centroCostos",
    reducers: {},
    initialState,
    extraReducers: {
      [obtenerCentrosCostos.pending]: (state, action) => {
        state.isLoading=true;
      },
      [obtenerCentrosCostos.fulfilled]: (state, action) => {
        state.centrosCostos = action.payload;
        state.isLoading=false;
      },
      [obtenerCentroCostoById.fulfilled]: (state, action) => {
        state.centroCosto = action.payload;
      },
    },
  });
  
  export default centrosCostosSlice.reducer;
  