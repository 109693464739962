import React, { useEffect, useState } from "react";
import spinner from "../IMG/SpinnerSending.gif";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import ContextActions from "../Components/DataBooks/ContextActions";
import PageActions from "../Components/DataBooks/PageActions";
import Card from "../Components/Other/Card";
import usePagination from "../Hooks/usePagination";
import NoResults from "../IMG/NoResults.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  almacenarImportacionDatos,
  aplicarImportacionDatos,
  obtenerCursos,
  obtenerFacturasServicios,
  obtenerProfesores,
  obtenerResumen,
} from "../Redux/reducers/importacionesSlice";
import FormToast from "../Components/Form/FormToast";
import useSubmit from "../Hooks/useSubmit";
import Options from "../Components/DataBooks/Options";
import { cambiarEstadoCurso } from "../Redux/reducers/cursosSlice";

export default function DetalleDatos() {
  const [dataProfesores, setDataProfesores] = useState();
  const [cursosOrdered, setCursosOrdered] = useState();
  const [dataCursos, setDataCursos] = useState();
  const [dataFacturas, setDataFacturas] = useState();
  const [isLoadingImport, setIsLoadingImport] = useState();
  const [registrosPorPaginaCursos, setRegistrosPorPaginaCursos] = useState(5);
  const [registrosPorPaginaFacturas, setRegistrosPorPaginaFacturas] =
    useState(5);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const {
    importacionProfesores,
    importacionCursos,
    isLoadingCursos,
    isLoadingProfesores,
    isLoadingFacturas,
    importacionResumen,
    importacionFacturaServicios,
  } = useSelector((state) => state.importaciones);

  const { isLoading } = useSelector((state) => state.cursos);

  const { onSubmit, successCode, sendingData } = useSubmit(
    false,
    (d) => aplicarImportacionDatos({ ImportacionId: id }),
    null,
    () => navigate("/cargaDatos")
  );

  const params = useParams();
  const { id, estado, mes, anio } = params;
  const [
    totalPagesFactura,
    startPageIndexFactura,
    endPageIndexFactura,
    currentPageIndexFactura, //ESLINT
    displayPageFactura,
  ] = usePagination(
    registrosPorPaginaFacturas,
    importacionFacturaServicios.length
  );

  const [
    totalPages,
    startPageIndex,
    endPageIndex,
    currentPageIndex, //ESLINT
    displayPage,
  ] = usePagination(registrosPorPaginaCursos, importacionCursos?.length);

  useEffect(() => {
    setDataProfesores(importacionProfesores);
  }, [importacionProfesores]);

  useEffect(() => {
    if (importacionCursos?.length > 0)
      setCursosOrdered(sortCourses(importacionCursos));
  }, [importacionCursos]);

  useEffect(() => {
    if (cursosOrdered?.length > 0)
      setDataCursos(cursosOrdered?.slice(startPageIndex, endPageIndex));
  }, [startPageIndex, endPageIndex, cursosOrdered]);

  useEffect(() => {
    setDataFacturas(
      importacionFacturaServicios?.slice(
        startPageIndexFactura,
        endPageIndexFactura
      )
    );
  }, [startPageIndexFactura, endPageIndexFactura, importacionFacturaServicios]);

  useEffect(() => {
    cargarProfesores();
    cargarCursos();
    cargarFacturas();
    dispatch(obtenerResumen({ ImportacionId: id }));
  }, []);

  useEffect(() => {
    cargarCursos()
  }, [isLoading])
  

  const cargarProfesores = (Buscar = "") => {
    dispatch(obtenerProfesores({ ImportacionId: id, Buscar }));
  };
  const cargarCursos = (NombreAsignatura = "") => {
    dispatch(obtenerCursos({ ImportacionId: id, NombreAsignatura }));
  };
  const cargarFacturas = (EstudianteNombre = "") => {
    dispatch(obtenerFacturasServicios({ IdImportacion: id, EstudianteNombre }));
  };
  const changeState = (curso) => {
    dispatch(cambiarEstadoCurso(curso));
  };

  const cargarDatos = () => {
    setIsLoadingImport(true);
    dispatch(almacenarImportacionDatos({ Ano: anio, Mes: mes }))
      .unwrap()
      .then((res) => {
        setIsLoadingImport(false);
        cargarProfesores();
        cargarCursos();
        cargarFacturas();
        dispatch(obtenerResumen({ ImportacionId: id }));
      });
  };

  const getMonthName = (id) => {
    let fecha = new Date();
    fecha.setMonth(id - 1);
    return new Intl.DateTimeFormat("es-ES", { month: "long" }).format(fecha);
  };

  const sortCourses = (arr = []) => {
    let result = [...arr];
    result?.sort((x, y) => {
      if (x.CodigoCurso < y.CodigoCurso) {
        return -1;
      }
      if (x.CodigoCurso > y.CodigoCurso) {
        return 1;
      }

      return x.Seccion.localeCompare(y.Seccion, "en", { numeric: true });
    });

    return result;
    // const result =arr;
    // result.sort((x, y) =>
    //   x.Seccion.localeCompare(y.Seccion, 'en', { numeric: true })
    // );
    // result.sort((x, y) =>
    //   x.CodigoCurso.localeCompare(y.CodigoCurso, 'en', { numeric: true })
    // );
    // return result;
  };

  const formatearFecha = (fecha) => {
    let fechaSinHora = fecha.split("T")[0];
    let fechaSplit = fechaSinHora.split("-");
    let nuevaFecha = fechaSplit[2] + "-" + fechaSplit[1] + "-" + fechaSplit[0];
    return nuevaFecha;
  };

  const getRowByGroups2 = (item, index) => {
    let seccionesFiltradas = dataProfesores.filter(
      (p) => p.CursosSecciones === item?.CursosSecciones
    );
    let profesoresFiltrados = dataProfesores.filter(
      (p) =>
        p.CursosProfesores === item?.CursosProfesores &&
        p.Profesor === item?.Profesor
    );

    let result =
      item.TipoMerge == 1 ? (
        item.CursosSecciones === dataProfesores[index - 1]?.CursosSecciones ? (
          <tr key={item.CursoProfesoresId}>
            <td>{item.Profesor}</td>
            <td>{item.TipoProfesor}</td>
            <td>{item.CostoTotal}</td>
            <td>{formatearFecha(item.FechaInicio)}</td>
            <td>{formatearFecha(item.FechaFin)}</td>
          </tr>
        ) : (
          <tr key={item.CursoProfesoresId}>
            <td rowSpan={seccionesFiltradas?.length}>{item.Curso}</td>
            <td rowSpan={seccionesFiltradas?.length}>{item.Seccion}</td>
            <td>{item.Profesor}</td>
            <td>{item.TipoProfesor}</td>
            <td>{item.CostoTotal}</td>
            <td>{formatearFecha(item.FechaInicio)}</td>
            <td>{formatearFecha(item.FechaFin)}</td>
          </tr>
        )
      ) : item.TipoMerge == 2 ? (
        item.CursosProfesores === dataProfesores[index - 1]?.CursosProfesores &&
        item.Profesor === dataProfesores[index - 1]?.Profesor ? (
          <tr key={item.CursoProfesoresId}>
            <td>{item.Curso}</td>
            <td>{item.Seccion}</td>
          </tr>
        ) : (
          <tr key={item.CursoProfesoresId}>
            <td>{item.Curso}</td>
            <td>{item.Seccion}</td>
            <td rowSpan={profesoresFiltrados?.length}>{item.Profesor}</td>
            <td rowSpan={profesoresFiltrados?.length}>{item.TipoProfesor}</td>
            <td rowSpan={profesoresFiltrados?.length}>{item.CostoTotal}</td>
            <td rowSpan={profesoresFiltrados?.length}>
              {formatearFecha(item.FechaInicio)}
            </td>
            <td rowSpan={profesoresFiltrados?.length}>
              {formatearFecha(item.FechaFin)}
            </td>
          </tr>
        )
      ) : (
        <tr key={item.CursoProfesoresId}>
          <td>{item.Curso}</td>
          <td>{item.Seccion}</td>
          <td>{item.Profesor}</td>
          <td>{item.TipoProfesor}</td>
          <td>{item.CostoTotal}</td>
          <td>{formatearFecha(item.FechaInicio)}</td>
          <td>{formatearFecha(item.FechaFin)}</td>
        </tr>
      );

    return result;
  };

  const renderTableProfesores = () => {
    return (
      <table className="table table-profesores">
        <thead>
          <tr>
            <th>Curso</th>
            <th>Sección</th>
            <th>Profesor</th>
            <th>Tipo</th>
            <th>Costo</th>
            <th>Fecha de inicio</th>
            <th>Fecha fin</th>
          </tr>
        </thead>
        <tbody>
          {dataProfesores?.map((item, index) => getRowByGroups2(item, index))}
        </tbody>
      </table>
    );
  };

  const renderTableCursos = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Código curso</th>
            <th>Curso</th>
            <th>Sección</th>
            <th>Moneda</th>
            <th>Nivel</th>
            <th>Total ingreso</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {dataCursos?.map((item) => (
            <tr key={`${item.CursoId}${item.Seccion}${item.Nivel}`}>
              <td>{item.CodigoCurso}</td>
              <td>{item.Curso}</td>
              <td>{item.Seccion}</td>
              <td>{item.Moneda}</td>
              <td>{item.Nivel}</td>
              <td>
                ${Intl.NumberFormat("es-419").format(item.Totalingreso)}
              </td>
              <td>{item.EstadoId === 1 ? "Activo" : "Excluido"}</td>
              <td>
                <Options
                  actions={[
                    {
                      icon: (
                        <i className="Material-Icons-Outlined">
                          {item.EstadoId === 1 ? "" : " "}
                        </i>
                      ),
                      text: item.EstadoId === 1 ? "Excluir" : "Activar",
                      onclick: () => {
                        changeState(item);
                      },
                    },
                  ]}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  const renderTableFacturas = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Año</th>
            <th>Periodo</th>
            <th>Fecha</th>
            <th>Concepto</th>
            <th>Moneda</th>
            <th>Estudiante</th>
            <th>Importe</th>
          </tr>
        </thead>
        <tbody>
          {dataFacturas.map((item) => (
            <tr key={item.FacturaId}>
              <td>{item.Ano}</td>
              <td>{item.Periodo}</td>
              <td>{formatearFecha(item.Fecha)}</td>
              <td>{item.concepto}</td>
              <td>{item.Moneda}</td>
              <td>{item.Estudiante}</td>
              <td>{Intl.NumberFormat("es-419").format(item.ImporteNeto)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <FormToast
        code={successCode.success}
        title={successCode.title}
        desc={successCode.message}
      />
      <ContextActions
        defaultSearch={false}
        showAll={false}
        customItem={
          <div className="flex-container">
            <button
              className="btn registers-btn-action"
              onClick={() => {
                cargarDatos();
              }}
            >
              Volver a cargar datos
            </button>

            {estado == 1 && (
              <>
                <button
                  className="btn btn-primary registers-btn-action"
                  onClick={onSubmit}
                >
                  Aplicar
                </button>
              </>
            )}
            <img
              className="img-spinner-registers"
              src={spinner}
              alt="animación enviando datos"
              hidden={!sendingData && !isLoadingImport}
            />
          </div>
        }
      />

      <motion.main
        id="main"
        className="register"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div style={{ width: "100%" }}>
          <div className="flex-container count-registers-container">
            <Card isLoading={false}>
              <div className="flex-container">
                <div>
                  <h3 className="title">Total de ingresos</h3>
                  <span className="number">
                    {Object.keys(importacionResumen[0]).length > 0
                      ? `${Intl.NumberFormat("es-419").format(
                          importacionResumen[0]?.TotalIngresos
                        )} ₡`
                      : "Cargando datos..."}
                  </span>
                </div>
                <i className="Material-Icons-Outlined"></i>
              </div>
            </Card>
            <Card isLoading={false}>
              <div className="flex-container">
                <div>
                  <h3 className="title">Total de profesores</h3>
                  <span className="number">
                    {Object.keys(importacionResumen[0]).length > 0
                      ? Intl.NumberFormat("es-419").format(
                          importacionResumen[0]?.totalProfesores
                        )
                      : "Cargando datos..."}
                  </span>
                </div>
                <i className="Material-Icons-Outlined"></i>
              </div>
            </Card>
            <Card isLoading={false}>
              <div className="flex-container">
                <div>
                  <h3 className="title">Total de secciones</h3>
                  <span className="number">
                    {Object.keys(importacionResumen[0]).length > 0
                      ? Intl.NumberFormat("es-419").format(
                          importacionResumen[0]?.TotalSecciones
                        )
                      : "Cargando datos..."}
                  </span>
                </div>
                <i className="Material-Icons-Outlined"></i>
              </div>
            </Card>
          </div>
          <Card classN="card" isLoading={isLoadingProfesores} type={"table"}>
            <h2 className="dark-text table-section-name">Profesores</h2>
            <h5 className="table-section-subname">
              Importación de {getMonthName(mes)}, {anio}
            </h5>
            <input
              className="registers-input superior-right-item"
              type="search"
              placeholder="Buscar por profesor..."
              onChange={(e) => cargarProfesores(e.target.value)}
            />
            {!dataProfesores?.length > 0 ? (
              <div className="no-results-container">
                <img src={NoResults} alt="Sin resultados"></img>
                <h2 className="grey-text">No se encontraron registros</h2>
              </div>
            ) : (
              <div className="table-container table-scroll">
                {renderTableProfesores()}
              </div>
            )}
          </Card>
          <Card classN="card" isLoading={isLoadingCursos || isLoading} type={"table"}>
            <h2 className="dark-text table-section-name">Cursos</h2>
            <h5 className="table-section-subname">
              Importación de {getMonthName(mes)}, {anio}
            </h5>
            <input
              className="registers-input superior-right-item"
              type="search"
              placeholder="Buscar por código o asignatura..."
              onChange={(e) => cargarCursos(e.target.value)}
            />
            {!dataCursos?.length > 0 ? (
              <div className="no-results-container">
                <img src={NoResults} alt="Sin resultados"></img>
                <h2 className="grey-text">No se encontraron registros</h2>
              </div>
            ) : (
              <div className="table-container">{renderTableCursos()}</div>
            )}
            <PageActions
              count={totalPages}
              onChange={(value) => displayPage(value)}
              changeRegistersCount={(value) =>
                setRegistrosPorPaginaCursos(value)
              }
              registersCount={registrosPorPaginaCursos}
            />
          </Card>
          <Card classN="card" isLoading={isLoadingFacturas} type={"table"}>
            <h2 className="dark-text table-section-name">
              Facturas de servicios
            </h2>
            <h5 className="table-section-subname">
              Importación de {getMonthName(mes)}, {anio}
            </h5>
            <input
              className="registers-input superior-right-item"
              type="search"
              placeholder="Buscar por estudiante..."
              onChange={(e) => cargarFacturas(e.target.value)}
            />
            {!dataFacturas?.length > 0 ? (
              <div className="no-results-container">
                <img src={NoResults} alt="Sin resultados"></img>
                <h2 className="grey-text">No se encontraron registros</h2>
              </div>
            ) : (
              <div className="table-container">{renderTableFacturas()}</div>
            )}
            <PageActions
              count={totalPagesFactura}
              onChange={(value) => displayPageFactura(value)}
              changeRegistersCount={(value) =>
                setRegistrosPorPaginaFacturas(value)
              }
              registersCount={registrosPorPaginaFacturas}
            />
          </Card>
        </div>
      </motion.main>
    </>
  );
}
