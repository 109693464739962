import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoResults from "../../../IMG/NoResults.svg";
import Card from "../../../Components/Other/Card";
import Options from "../../../Components/DataBooks/Options";
import { useNavigate } from "react-router-dom";
import FormToast from "../../../Components/Form/FormToast";
import { obtenerCentroCostoById, obtenerCentrosCostos} from "../../../Redux/reducers/centrosCostosSlice";
import FormCentrosCosto from "./FormCentrosCosto";

function CentroCosto() {
  const [formState, setFormState] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [successCode, setSuccessCode]=useState(0)
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const { centrosCostos, centroCosto, isLoading } = useSelector((state) => state.centrosCostos);
  const [toastMessage,setToastMessage]=useState();

  useEffect(() => {
   cargarDatos()
  }, []);

  const cargarDatos=()=>{
    dispatch(obtenerCentrosCostos());
  }

  const obtenerRegistro = (id) => {
    dispatch(obtenerCentroCostoById(id));
  };

  const setToast=(code,message)=>{
    setSuccessCode(code);
    setToastMessage(message)
    setTimeout(() => {setSuccessCode(0);setToastMessage(null)}, 4000);
  }


  const renderTable = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Código</th>
            <th>Centro de costo</th>
            <th>Alias</th>
            <th>Código contable</th>
          </tr>
        </thead>
        <tbody>
          {centrosCostos?.map((item, index) => (
            <tr key={item.CentroCostoId}>
              <td>{item.CentroCostoId}</td>
              <td>{item.Nombre}</td>
              <td>{item.Alias? item.Alias:"N/A"}</td>
              <td>{item.Codigo}</td>
              {/* <td>
                <Options
                  actions={[
                    {
                      icon: <i className="Material-Icons-Outlined"></i>,
                      text: "Editar",
                      onclick: () => {
                          setFormState(true);
                          setIsEditing(true);
                          obtenerRegistro(item.Id);
                       
                      },
                    },
                    
                  ]}
                />
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <FormToast
                code={successCode}
                title={successCode == 2 ? "¡Algo anda mal!": "¡Bien!"}
                desc={toastMessage!==null?toastMessage: successCode == 2? "Revisa los datos e intenta nuevamente" : "Centro de costo almacenado"}
              />

      <FormCentrosCosto
        isEditing={formState && isEditing}
        close={(e) => {
          e?.preventDefault();
          setFormState(false);
          setIsEditing(false);
        }}
        isVisible={formState}
        centroDatos={isEditing? centroCosto:{}}
        reload={()=>cargarDatos()}
        setCode={(a)=>setToast(a,null)}
      />
      <motion.main
        id="main"
        className="register"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="form-title">
          <h2 className="form-title">Configuración de centros de costos</h2>
          <button
            className="btn alternative-btn"
            onClick={() => {
              navigate('./logs/CentrosCostos');
            }}
          >
            Cambios realizados
          </button>
          {/* <br/>
          <button
            className="btn btn-primary"
            onClick={() => {setIsEditing(false); setFormState(true);}}
          >
            Nuevo centro de costo
          </button> */}
        </div>
        <Card
          classN="card plantillas-card container-col-whole-space scroll-view"
          isLoading={isLoading}
          type="table"
        >
          {centrosCostos?.length > 0 ? (
            <div className="table-container">{renderTable()}</div>
          ) : (
            <div className="no-results-container">
              <img src={NoResults} alt="Sin resultados"></img>
              <h2 className="grey-text">No se encontraron registros</h2>
            </div>
          )}
        </Card>
      </motion.main>
    </>
  );
}
export default CentroCosto;
