import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";


export const obtenerTiposProfesores = createAsyncThunk(
  "genericos/obtenerTiposProfesores",
  async (n, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("TiposProfesores");
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);



const initialState = {
  tiposProfesores: [],
};

export const genericosSlice = createSlice({
  name: "genericos",
  reducers: {},
  initialState,
  extraReducers: {
    [obtenerTiposProfesores.fulfilled]: (state, action) => {
      state.tiposProfesores = action.payload;
    },
  },
});

export default genericosSlice.reducer;
