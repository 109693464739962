import React, { forwardRef, useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FormInput } from "../Form/FormElements";
import FormButtons from "./FormButtons";

const Form = forwardRef((props, ref) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "dynamicFieldArray",
  });

  useEffect(() => {
    if (props.fieldArray) append({});
  }, []);

  useEffect(() => {
    if (!props.isEditing && props.successCode == 1) {
      reset({});
    }
  }, [props.isEditing, props.successCode]);
  
  useEffect(() => {
    if (props.dataToEdit !== null && props.isEditing) {
      reset(props.dataToEdit)};
  }, [reset, props.dataToEdit, props.dynamicForm, props.isEditing]);

  const formInputs = Object.keys(props.dynamicForm).map((e) => {
    if (props.dynamicForm[e] === props.dynamicForm.dynamicFieldArray) {
      return (
        <section key={e}>
          {fields.map((field, index) => (
            <div key={field.id}>
              <div style={props.style}>
                {Object.keys(props.dynamicForm[e]).map((a) => (
                  <div key={a} style={props.dynamicForm[e][a].style}>
                    <Controller
                      name={`${e}.${index}.${a}`}
                      control={control}
                      rules={props.dynamicForm[e][a].rules}
                      defaultValue={props.dynamicForm[e][a].defaultValue}
                      render={({ field }) => (
                        <div>
                          <FormInput
                            error={errors[a] && "Este campo es requerido"}
                            value={field.value}
                            onChange={(v) => {
                              field.onChange(v);
                              if (props.dynamicForm[e][a].changeHand) {
                                props.dynamicForm[e][a].changeHand(v);
                              }
                            }}
                            {...props.dynamicForm[e][a]}
                          />
                        </div>
                      )}
                    />
                  </div>
                ))}
              </div>
              <button
                className="btn simple-btn array-btn-delete"
                onClick={() => remove(index)}
              >
                <i className="material-icons"></i>
                {props.removeArrayLabel}
              </button>
            </div>
          ))}
          <button
            className="btn simple-btn"
            onClick={(e) => {
              e.preventDefault();
              append({});
            }}
          >
            <i className="material-icons"></i>
            {props.btnAddToArray}
          </button>
        </section>
      );
    } else {
      const { rules, defaultValue } = props.dynamicForm[e];
      return (
        <section key={e} style={props.dynamicForm[e].style}>
          <Controller
            name={e}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field }) => (
              <div>
                <FormInput
                  error={errors[e] && "Este campo es requerido"}
                  value={field.value}
                  onChange={(v) => {
                    field.onChange(v);
                    if (props.dynamicForm[e].changeHand)
                      props.dynamicForm[e].changeHand(v);
                  }}
                  required={props.dynamicForm[e].required}
                  {...props.dynamicForm[e]}
                />
              </div>
            )}
          />
        </section>
      );
    }
  });

  return (
    <form style={!props.fieldArray ? props.style : null}>
      {formInputs}
      {!props.formButtons ? (
        <FormButtons
        loading={props.loading}
          progressAction={props.progressAction? props.progressAction:"Guardar"}
          onProgress={handleSubmit(props.onSubmit)}
          onRecoil={(e) => {
            e.preventDefault();
            props.onRecoil();
          }}
        />
      ) : (
        props.formButtons
      )}
    </form>
  );
});

export default Form;
