import React, { useEffect } from "react";
import useComponentVisible from "../../Hooks/useComponentVisible";
import MenuItem from "./MenuItem";

function SideSubmenu({ isSubmenuActive, displaySubMenu }) {
  const { ref, isComponentVisible } = useComponentVisible(isSubmenuActive);

  return (
    <div className="flex-container" id="overlay-container">
      <section
        ref={ref}
        className={
          !isComponentVisible
            ? "sub-menu-container is-hidden"
            : "sub-menu-container"
        }
      >
        <button className="btn is-visible-on-mobile" onClick={displaySubMenu}>
          <i className="material-icons dark-text"></i>
        </button>
        <ul>
          <li>
            <MenuItem
              route="Estadisticas"
              class="btn sub-menu-button"
              icon={<i className="Material-Icons-Outlined"></i>}
              name="Cálculos estadísticos"
            />
          </li>
          <li>
            <MenuItem
              class="btn sub-menu-button"
              icon={<i className="Material-Icons-Outlined"></i>}
              name="Inserción de
          gráficos"
            />
          </li>
          <li>
            <MenuItem
              class="btn sub-menu-button"
              icon={<i className="material-icons"></i>}
              name="Porcentajes"
            />
          </li>
          <li>
            <MenuItem
              class="btn sub-menu-button"
              icon={<i className="material-icons"></i>}
              name="Planteamientos"
            />
          </li>
          <li>
            <MenuItem
              class="btn sub-menu-button"
              icon={<i className="material-icons"></i>}
              name="Distribución estadística"
            />
          </li>
        </ul>
      </section>
      <div
        id="submenu-overlay"
        className={
          !isComponentVisible
            ? "is-hidden"
            : null
        }
      ></div>
    </div>
  );
}

export default SideSubmenu;
