import React, { useEffect, useRef, useState } from "react";
import FormToast from "../Components/Form/FormToast";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import ContextActions from "../Components/DataBooks/ContextActions";
import Options from "../Components/DataBooks/Options";
import PageActions from "../Components/DataBooks/PageActions";
import Card from "../Components/Other/Card";
import usePagination from "../Hooks/usePagination";
import NoResults from "../IMG/NoResults.svg";
import { useNavigate } from "react-router-dom";
import {
  ImportarAsiento,
  obtenerAsientosExcelResumen,
  obtenerAsientosGeneral,
  obtenerFallosAsiento,
} from "../Redux/reducers/asientosSlice";
import useSubmit from "../Hooks/useSubmit";
import Modal from "../Components/Other/Modal";
import Form from "../Components/Form/Form";

const periodos = [
  { periodoId: 1, periodoNombre: "Enero" },
  { periodoId: 2, periodoNombre: "Febrero" },
  { periodoId: 3, periodoNombre: "Marzo" },
  { periodoId: 4, periodoNombre: "Abril" },
  { periodoId: 5, periodoNombre: "Mayo" },
  { periodoId: 6, periodoNombre: "Junio" },
  { periodoId: 7, periodoNombre: "Julio" },
  { periodoId: 8, periodoNombre: "Agosto" },
  { periodoId: 9, periodoNombre: "Septiembre" },
  { periodoId: 10, periodoNombre: "Octubre" },
  { periodoId: 11, periodoNombre: "Noviembre" },
  { periodoId: 12, periodoNombre: "Diciembre" },
];

export default function MenuAsientos() {
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState();
  const [registrosPorPagina, setRegistrosPorPagina] = useState(5);
  const [showModal, setShowModal] = useState();
  const { meses, fallosAsiento, isLoading } = useSelector(
    (state) => state.asientos
  );
  const [mesForm, setMesForm] = useState();
  const [anoForm, setAnoForm] = useState();
  const [fallosAsientoState, setFallosAsientos] = useState(0);
  const anioActual = new Date().getFullYear();

  const [search, setSearch] = useState({ anioActual });

  const cargarDatos = (Ano = anioActual) => {
    setShowModal(false);
    dispatch(obtenerAsientosGeneral({ Ano }));
  };

  const { onSubmit, successCode, sendingData } = useSubmit(
    false,
    ImportarAsiento,
    null,
    cargarDatos
  );

  const modelForm = {
    Ano: {
      label: "Año",
      type: "number",
      placeholder: "Año",
      required: true,
      changeHand: (e) => setAnoForm(e.target.value),
      rules: {
        required: true,
      },
    },
    Mes: {
      label: "Mes",
      type: "dropdown",
      changeHand: (e) => setMesForm(e.target.value),
      options: periodos,
      optionIdName: "periodoId",
      optionValueName: "periodoNombre",
      required: true,
      rules: {
        required: true,
      },
    },
  };

  const [
    totalPages,
    startPageIndex,
    endPageIndex,
    currentPageIndex, //ESLINT
    displayPage,
  ] = usePagination(registrosPorPagina, meses.length);

  useEffect(() => {
    setData(meses?.slice(startPageIndex, endPageIndex));
  }, [startPageIndex, endPageIndex, meses]);

  useEffect(() => {
    setRegistrosPorPagina(1);
    cargarDatos(search.anioActual);
  }, [search]);
  useEffect(() => {
    if (fallosAsiento?.length > 0) {
      setFallosAsientos(1);
    } else if (fallosAsiento !== null) {
      setFallosAsientos(2);
    }
  }, [fallosAsiento]);

  useEffect(() => {
    if (registrosPorPagina < 5) {
      setRegistrosPorPagina(5);
    }
  }, [registrosPorPagina]);

  useEffect(() => {
    setFallosAsientos(0);
  }, [anoForm, mesForm, showModal]);

  const validarAsiento = async (data) => {
    dispatch(obtenerFallosAsiento(data));
  };

  const editModal = () => {
    setShowModal(true);
  };
  const renderTable = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Año</th>
            <th>Mes</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => (
            <tr key={item.MesId}>
              <td>{item.Anio}</td>
              <td>{item.Mes}</td>
              <td>
                <Options
                  actions={[
                    {
                      onclick: () => navigate(`./asientosPorMes/${item.Anio}/${item.MesId}`),
                      icon: <i className="Material-Icons-Outlined"></i>,
                      text: "Ver asientos del mes",
                    },
                    {
                      onclick: () =>
                        dispatch(
                          obtenerAsientosExcelResumen({
                            Ano: item.Anio,
                            Mes: item.MesId,
                          })
                        ),
                      icon: <i className="Material-Icons-Outlined"></i>,
                      text: `Descargar resumen`,
                    },
                  ]}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };


  return (
    <>
      <FormToast
        code={successCode.success}
        title={successCode.title}
        desc={successCode.message}
      />
      <Modal isVisible={showModal} title={"Crear asiento contable"}>
        <Form
          ref={formRef}
          dynamicForm={modelForm}
          onSubmit={
            fallosAsientoState == 2 && fallosAsiento?.length === 0
              ? onSubmit
              : validarAsiento
          }
          onRecoil={() => {
            setShowModal(false);
          }}
          loading={sendingData}
          isEditing={false}
          progressAction={
            fallosAsientoState == 2 && fallosAsiento?.length === 0
              ? "Crear"
              : "Validar"
          }
        />
        {fallosAsientoState === 2
          ? "No se encontraron fallos"
          : fallosAsientoState === 1
          ? fallosAsiento?.map((fallo, i) => (
              <span className="is-invalid" key={i}>
                {fallo.Cantidad} {fallo.Problema}
              </span>
            ))
          : ""}
      </Modal>

      <ContextActions
        onClick={() => {
          editModal(null);
        }}
        defaultSearch={false}
        btnNew="Añadir"
        showAll={false}
        customItem={
          <div className="flex-container">
            <input
              className="registers-input"
              type="number"
              defaultValue={anioActual}
              placeholder="Año"
              onChange={(e) =>
                setSearch({ ...search, anioActual: e.target.value })
              }
            />
          </div>
        }
      />

      <motion.main
        id="main"
        className="register"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Card
          classN="card container-col-whole-space"
          isLoading={isLoading}
          type={"table"}
        >
          <h2 className="dark-text table-section-name">Asientos contables</h2>
          {!data?.length > 0 ? (
            <div className="no-results-container">
              <img src={NoResults} alt="Sin resultados"></img>
              <h2 className="grey-text">No se encontraron registros</h2>
            </div>
          ) : (
            <div className="table-container">{renderTable()}</div>
          )}
          <PageActions
            count={totalPages} //usuarios.PageCount
            onChange={(value) => displayPage(value)}
            changeRegistersCount={(value) => setRegistrosPorPagina(value)}
            registersCount={registrosPorPagina} //usuarios.RowsCount
          />
        </Card>
      </motion.main>
    </>
  );
}
