import { useState } from "react";
import { useDispatch } from "react-redux";

function useSubmit(boolEditing, postSubmit, putSubmit, callbackfunction) {
  const [sendingData, setSendingData] = useState();
  const [successCode, setSuccessCode] = useState(0);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    let result;
    setSendingData(true);
    if (boolEditing) {
      let { payload } = await dispatch(putSubmit(data));
      result = payload;
    } else {
      let { payload } = await dispatch(postSubmit(data));
      result = payload;
    }
    setSuccessCode(result);
    setSendingData(false);
    setTimeout(() => setSuccessCode(0), 4000);
    if(callbackfunction)
    callbackfunction();
  };

  return { onSubmit, sendingData, successCode };
}

export default useSubmit;
