import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";

export const aplicarImportacionDatos = createAsyncThunk(
  "importacionDatos/aplicarImportacionDato",
  async (item, { rejectWithValue }) => {
    try {
      delete item.dynamicFieldArray;
      const res = await WebApi.api().post("Importaciones/Aplicar", item);
      return {
        success: 1,
        title: "¡Bien!",
        message: "Importación de datos aplicada",
        show: true,
        res,
      };
    } catch (err) {
      return {
        success: 2,
        title: "Algo anda mal",
        message: "Intente de nuevo más tarde",
        show: true,
      };
    }
  }
);
export const almacenarImportacionDatos = createAsyncThunk(
  "importacionDatos/almacenarImportacionDato",
  async (item, { rejectWithValue }) => {
    try {
      delete item.dynamicFieldArray;
      const res = await WebApi.api().post("Importaciones", {
        ...item,
        EstadoId: 1,
      });
      return {
        success: true,
        message: "Importación realizada",
        show: true,
        res:res.data.Result,
      };
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

export const obtenerImportacionDatos = createAsyncThunk(
  "importacionDatos/obtenerImportacionDatos",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("Importaciones/GetListado",item);
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const obtenerResumen = createAsyncThunk(
  "importacionDatos/obtenerResumen",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("Importaciones/GetResumen", item);
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const obtenerProfesores = createAsyncThunk(
  "importacionDatos/obtenerProfesores",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("Importaciones/GetProfesores", item);
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const obtenerCursos = createAsyncThunk(
  "importacionDatos/obtenerCursos",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("Importaciones/GetCursos", item);
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const obtenerFacturasServicios = createAsyncThunk(
  "importacionDatos/facturaServicios",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("FacturasServicios/Listado", item);
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  importacionDatos: [],
  importacionDato: {},
  isLoading: true,
  importacionDatosTipos: [],
  importacionResumen: [{}],
  importacionProfesores: [],
  importacionCursos: [],
  importacionFacturaServicios: [],

  isLoadingProfesores: false,
  isLoadingCursos: false,
  isLoadingFacturas: false,
};

export const importacionDatosSlice = createSlice({
  name: "importacionDatos",
  reducers: {},
  initialState,
  extraReducers: {
    [obtenerImportacionDatos.pending]: (state, action) => {
      state.isLoading = true;
    },
    [obtenerImportacionDatos.fulfilled]: (state, action) => {
      state.importacionDatos = action.payload;
      state.isLoading = false;
    },
    [almacenarImportacionDatos.fulfilled]: (state, action) => {
      state.importacionDato = action.payload;
    },
    [obtenerResumen.fulfilled]: (state, action) => {
      state.importacionResumen = action.payload;
    },
    [obtenerCursos.pending]: (state, action) => {
      state.isLoadingCursos = true;
    },
    [obtenerCursos.fulfilled]: (state, action) => {
      state.importacionCursos = action.payload;
      state.isLoadingCursos = false;
    },
    [obtenerProfesores.pending]: (state, action) => {
      state.isLoadingProfesores = true;
    },
    [obtenerProfesores.fulfilled]: (state, action) => {
      state.importacionProfesores = action.payload;
      state.isLoadingProfesores = false;
    },
    [obtenerFacturasServicios.pending]: (state, action) => {
      state.isLoadingFacturas = true;
    },
    [obtenerFacturasServicios.fulfilled]: (state, action) => {
      state.importacionFacturaServicios = action.payload;
      state.isLoadingFacturas = false;
    },
  },
});

export default importacionDatosSlice.reducer;
