import React from "react";
import { useState } from "react";
import Modal from "./Modal";
import Security from "../../IMG/Security.svg";
import ImportacionDatos from "../../IMG/userGuideAssets/importacionDatos.gif";
import CreacionAsientos from "../../IMG/userGuideAssets/creacionAsiento.gif";
import VistaAsientos from "../../IMG/userGuideAssets/filtroYDetalleAsiento.gif";
import CentrosCostos from "../../IMG/userGuideAssets/configuracion.gif";
import EdicionPrecios from "../../IMG/userGuideAssets/modificarPrecios.gif";

function Guide() {
  const guideSteps = [
    {
      img: ImportacionDatos,
      title: "Importe los datos contables en cuestion de segundos",
      text: "En el apartado de carga de datos, puede realizar la búsqueda de las importaciones ya realizadas por año y mes. Haga clíc en el botón de añadir para cargar nuevos datos. Una vez importados los datos del mes seleccionado, verá la vista del detalle de los datos extraídos, donde puede aplicarlos haciendo clíc en el botón de 'aplicar'.",
    },
    {
      img: EdicionPrecios,
      title: "Modifique los precios de los docentes de la forma que prefiera",
      text: "Si hace clíc derecho en el ícono de los tres puntos situados a la derecha de cada fila, podrá ver la opción de editar precios para ese mes. Al hacer clíc aquí, se mostrará la lista de cursos y profesores con los campos modificables de tipo y precio. Puede elegir modificar estos campos, o puede descargar la plantilla de excel con las columnas correspondientes, y posteriormente subir el archivos con los precios y tipos modificados.",
    },
    {
      img: VistaAsientos,
      title: "Gestione sus asientos contables de forma sencilla.",
      text: "Puede filtrar la información de sus asientos por año y mes, y descargar los datos en formato excel, así como el resumen para el mes indicado, y los anexos. Tambien puede elegir ver el detalle de un anexo, el cual dispondrá de las opciones de aplicar, y descargar el detalle en formato excel, situadas a la derecha superior de la vista.",
    },
    {
      img: CreacionAsientos,
      title: "Añada asientos contables de forma segura.",
      text: "Al hacer clíc en el botón de añadir, sera capaz de escoger el año y el mes del asiento que desee añadir. Haga clíc en validar para asegurarse de que no hacen falta datos correspondientes al asiento. Si aparece el mensaje 'No se encontraron fallos', puede darle clíc en 'guardar' para almacenar el asiento. En caso de que aparezca un mensaje de color rojo notificando sobre una cantidad de profesores con precios nulos, asegurese de indicar esos precios en el apartado de 'Precios de profesores' de la carga de datos. ",
    },
    {
      img: CentrosCostos,
      title: "Administre sus centros de costo",
      text: "Acceda a los centros de costo haciendo clíc en la rueda de configuración en el encabezado de la página. Aquí podra visualizar los centros de costo registrados, así como el historial de cambios realizados en los centros de costo. Tambien cuenta con la opción de editar la información de un centro de costo, haciendo clíc en los tres puntos a la derecha, y agregar un nuevo centro de costo con la opción 'Nuevo centro de costo'.",
    },
  ];
  const [isVisible, setVisibility] = useState();
  const [activeSection, setActiveSection] = useState(-1);

  return (
    <>
      <button className="btn grey-text" onClick={() => setVisibility(true)}>
        <i className="material-icons"></i>
      </button>
      <Modal
        title="Manual de ayuda"
        isVisible={isVisible}
        bottomItems={
          <div className="form-btn-group">
            {activeSection >= 0 ? (
              <button
                type="button"
                className="btn alternative-btn"
                onClick={() => setActiveSection(activeSection - 1)}
              >
                Atrás
              </button>
            ) : (
              <button
                type="button"
                className="btn alternative-btn"
                onClick={() => {
                  setActiveSection(-1);
                  setVisibility(false);
                }}
              >
                Cerrar
              </button>
            )}
            {activeSection + 1 != guideSteps.length ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setActiveSection(activeSection + 1);
                }}
              >
                Siguiente
              </button>
            ) : (
              <button
                type="button"
                className="btn alternative-btn"
                onClick={() => {
                  setActiveSection(-1);
                  setVisibility(false);
                }}
              >
                Cerrar
              </button>
            )}
          </div>
        }
      >
        <div className="guide-container">
          {activeSection === -1 ? (
            <section className="flex-container guide-introduction">
              <div className="container-col-30">
                <img alt="Ilustración onboarding" src={Security} />
              </div>
              <div className="container-col-75">
                Administra tus registros contables de forma simple y segura.
              </div>
            </section>
          ) : (
            <section>
              <h4>{guideSteps[activeSection].title}</h4>
              <div className="container-col-75">
                <img alt="Gif de ayuda" src={guideSteps[activeSection].img} />
              </div>
              <div>{guideSteps[activeSection].text}</div>
            </section>
          )}
        </div>
      </Modal>
    </>
  );
}

export default Guide;
