import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function Breadcrumb({ crumbs }) {
  const [historial, setHistorial] = useState([]);
  const location = useLocation();
  const [ids, setIds] = useState([]);

  useEffect(() => {
    setHistorial(location.pathname.split("/"));
  }, [location.pathname]);

  useEffect(() => {
    setIds(historial.filter((x) => x / 1));
  }, [historial]);

  const generateLinks = () => {
    let crumbsArray = crumbs()
      .filter(({ name }) => historial.includes(name))
      .map(({ path, title }, i) => {
        const k = [...ids];
        return {
          path:
            new RegExp(/(:\w*)/g).test(path) && k.length > 0
              ? path.replace(new RegExp(/(:\w*)/g), (i) => k.shift())
              : path,
          title: title,
        };
      });
    return crumbsArray.sort((a, b) => a.path.length - b.path.length);
  };

  return (
    <div id="navigation-tab">
      <div className="flex-container">
        {generateLinks().map(({ path, title }) =>
          path !== "" ? (
            <Link key={path} to={path} className="grey-text flex-container">
              {title} <i className="material-icons"></i>
            </Link>
          ) : null
        )}
      </div>
      <hr />
    </div>
  );
}

export default Breadcrumb;
