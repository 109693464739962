import React, { useState } from "react";

export default function LogTableRow(props) {
  const { data, usuario } = props;
  const [expandedRowId, setExpandedRowId] = useState(null);
  const handleRowClick = (rowId) => {
    if (expandedRowId === rowId) {
      setExpandedRowId(null);
    } else {
      setExpandedRowId(rowId);
    }
  };

  if (data)
    return (
      <>
        {data.Changes.map((e, i) => (
          <tr key={e.Id} className="low-row">
            <td className="log-row-usuario">{i == 0 ? usuario : null}</td>
            <td className="log-row-icon">
              {i == 0 && (
                <i className="material-icons">
                  {data.Action == "Update" ? (
                    <span> </span>
                  ) : data.Action == "Delete" ? (
                    <span></span>
                  ) : (
                    <span></span>
                  )}
                </i>
              )}
            </td>
            <td className="log-row-campo">{e.ColumnName}</td>
            <td className="log-row-anterior">{e.OriginalValue}</td>
            <td className="log-row-nuevo">{e.CurrentValue}</td>
            <td className="log-row-icon"></td>
          </tr>
        ))}
        {data.Children != [] &&
          data.Children.map((e, i) => (
            <>
              <tr
                key={e.Id}
                className="low-row"
                onClick={() => handleRowClick(e.Id)}
              >
                <td></td>
                <td>
                  <i className="material-icons">
                    {e.Action == "Update" ? (
                      <span> </span>
                    ) : e.Action == "Delete" ? (
                      <span></span>
                    ) : (
                      <span></span>
                    )}
                  </i>
                </td>
                <td className="log-row-campo">{e.TableName}</td>
                <td className="log-row-anterior">{e.OriginalValue}</td>
                <td className="log-row-nuevo">{e.CurrentValue}</td>

                <td className="log-row-icon">
                  {data.Children != [] ? (
                    expandedRowId === e.Id ? (
                      <i class="Material-Icons-Outlined"></i>
                    ) : (
                      <i class="Material-Icons-Outlined"></i>
                    )
                  ) : null}
                </td>
              </tr>
              {
                expandedRowId === e.Id && e.Changes && (
                  <LogTableRow key={e.Id} data={e} />
                )
              }
            </>
          ))}
      </>
    );
}
