import React from "react";
import ConfiguracionesGenerales from "../../Views/Configuracion/ConfiguracionesGenerales";
import Plantillas from "../../Views/Configuracion/Plantillas/Plantillas";
import Password from "../../Views/Configuracion/Password";
import { useState } from "react";
import CentroCosto from "../../Views/Configuracion/CentrosCostos/CentroCosto";
import NivelAcademico from "../../Views/Configuracion/NivelesAcademicos/NivelAcademico";
import TipoCuenta from "../../Views/Configuracion/TiposCuentas/TipoCuenta";

function SettingsMenu({ handleEvent }) {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    { name: "Centros de costo", component: <CentroCosto /> },
    { name: "Niveles académicos", component: <NivelAcademico /> },
    { name: "Tipos de cuenta", component: <TipoCuenta /> },

    //{ name: "Gestión de contraseña", component: <Password /> },
    // { name: "Plantillas", component: <Plantillas /> },
    // {
    //   name: "Configuraciones generales",
    //   component: <ConfiguracionesGenerales />,
    // },
  ];
  return (
    <div className="settings-menu-mobile">
      <button className="btn is-visible-on-mobile">
        <i className="material-icons"></i>
      </button>
      <div className="nav-settings-container">
        <div className="nav-content">
          <ul className="nav-settings-container">
            {tabs.map((tab, index) => (
              <li key={index}
                onClick={() => {
                  handleEvent(tab.component);
                  setActiveTab(index);
                }}
                className={`nav-item-settings " ${
                  activeTab == index && "settings-tab-selected"
                }`}
              >
                {tab.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <button className="btn is-visible-on-mobile">
        <i className="material-icons"></i>
      </button>
    </div>
  );
}

export default SettingsMenu;
