import { useRoutes } from "react-router-dom";
import ProtectedRoute from "./Components/Other/ProtectedRoute";
import ChangePassword from "./Pages/ChangePassword";
import ForgotPassword from "./Pages/ForgotPassword";
import Login from "./Pages/Login";
import NoPage from "./Pages/NoPage";
import Principal from "./Pages/Principal";
import Required from "./Pages/Required";
import ListUsuarios from "./Views/ListUsuarios";
import Logs from "./Views/logs/Logs";
import Settings from "./Views/Configuracion/Settings";
import Dashboard from "./Views/Dashboard";
import CargaDatos from "./Views/CargaDatos";
import PreciosProfesores from "./Views/PreciosProfesores";
import Asientos from "./Views/Asientos";
import DetalleAsiento from "./Views/DetalleAsiento";
import DetalleDatos from "./Views/DetalleDatos";
import MenuAsientos from "./Views/MenuAsientos";
const crumbs = () => {
  const adminPrivateRoutes=routes[0].children[0].children.find(e=>e.title=="protected Admin").children;
  const c = routes[0].children[0].children;
  return c.concat(adminPrivateRoutes);
};


const routes=[
    {
      title: "protected",
      path: "/",
      element: <ProtectedRoute allowedRoles={["administrador(a) del sistema"]} />,
      children: [
        {
          title: "principal",
          path: "/",
          element: <Principal crumbs={crumbs}/>,
          children: [
            {
              title: "Dashboard",
              path: "/",
              element: <Dashboard />,
              name:"Dashboard",
            },
            {
              title: "Carga de datos",
              path: "/cargaDatos",
              element: <CargaDatos />,
              name:"cargaDatos",
            },
            {
              title: "Detalle datos",
              path: "/cargaDatos/detalleDatos/:id/:estado/:mes/:anio",
              element: <DetalleDatos/>,
              name:"detalleDatos",
            },
            {
              title: "Precios de los profesores",
              path: "/cargaDatos/preciosProfesores/:id/:mes/:anio",
              element: <PreciosProfesores />,
              name:"preciosProfesores",
            },
            {
              title: "Cambios de precios",
              path: "/cargaDatos/preciosProfesores/:id/:mes/:anio/logs/:tableName",
              element: <Logs />,
              name: "cambiosPrecios",
            },
            {
              title: "Asientos",
              path: "/asientos",
              element: <MenuAsientos />,
              name:"asientos",
            },
            {
              title: "Asientos por mes",
              path: "/asientos/asientosPorMes/:anio/:mes",
              element: <Asientos />,
              name:"asientosPorMes",
            },
            {
              title: "Detalle Asiento",
              path: "/asientos/asientosPorMes/:anio/:mes/detalleAsiento/:id/:estado",
              element: <DetalleAsiento/>,
              name:"detalleAsiento",
            },
            {
              title: "Configuración",
              path: "configuraciones",
              element: <Settings />,
              name:"configuraciones"
            },
            {
              title: "protected Admin",
              path: "/",
              element: <ProtectedRoute allowedRoles={"administrador(a) del sistema"}/>,
              children:[
                  {
                    title: "Usuarios",
                    path: "usuarios",
                    element: <ListUsuarios />,
                    name: "usuarios",
                  },
                  {
                    title: "Cambios de usuarios",
                    path: "usuarios/logs/:tableName",
                    element: <Logs />,
                    name: "cambiosUsuarios",
                  },
                  {
                    title: "Cambios de centros de costo",
                    path: "configuraciones/logs/:tableName",
                    element: <Logs />,
                    name: "cambiosCentrosCostos",
                  },
                  
              ]
            },
          ],
        },
      ],
    },
    { title: "Login", path: "login", element: <Login />},
    {
      title: "forgotPassword", path: "login/forgotPassword", element: <ForgotPassword />
    },
    {
      title: "recoverPassword", path: "recover/recover", element: <ChangePassword />
    },
    { title: "Required", path: "required", element: <Required /> },
    { title: "Not Found", path: "*", element: <NoPage /> },
  ];

  

export const RouteElements = () => {
  let element = useRoutes(routes);
  return element;
};
