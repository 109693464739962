import React, { useEffect, useState } from "react";
import spinner from "../IMG/SpinnerSending.gif";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import ContextActions from "../Components/DataBooks/ContextActions";
import PageActions from "../Components/DataBooks/PageActions";
import Card from "../Components/Other/Card";
import usePagination from "../Hooks/usePagination";
import NoResults from "../IMG/NoResults.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  obtenerDetalleAsiento,
  aplicarAsiento,
  obtenerAsientosExcelPorId,
} from "../Redux/reducers/asientosSlice";
import useSubmit from "../Hooks/useSubmit";

export default function DetalleAsiento() {
  const [data, setData] = useState();
  const [registrosPorPagina, setRegistrosPorPagina] = useState(5);
  const dispatch = useDispatch();
  const { detalleAsiento, isLoading } = useSelector((state) => state.asientos);

  const navigate = useNavigate();

  const params = useParams();
  const { id, estado } = params;

  const { onSubmit, successCode, sendingData } = useSubmit(
    false,
    (d) => aplicarAsiento({ AsientoId: id }),
    null,
    () => navigate("/asientos")
  );

  const [
    totalPages,
    startPageIndex,
    endPageIndex,
    currentPageIndex, //ESLINT
    displayPage,
  ] = usePagination(registrosPorPagina, detalleAsiento.length);

  useEffect(() => {
    setData(detalleAsiento?.slice(startPageIndex, endPageIndex));
  }, [startPageIndex, endPageIndex, detalleAsiento]);

  useEffect(() => {
    cargarDatos();
  }, []);

  const cargarDatos = () => {
    dispatch(obtenerDetalleAsiento({ AsientoId: id }));
  };

  const formatearFecha = (fecha) => {
    let fechaSinHora = fecha.split("T")[0];
    let fechaSplit = fechaSinHora.split("-");
    let nuevaFecha = fechaSplit[2] + "-" + fechaSplit[1] + "-" + fechaSplit[0];
    return nuevaFecha;
  };

  const renderTable = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Línea</th>
            <th>Fecha</th>
            <th>Centro de costo</th>
            <th>Cuenta</th>
            <th>Concepto</th>
            <th>Deber</th>
            <th>Haber</th>
            <th>Moneda</th>
            <th>Nota</th>
            <th>Unidad</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index}>
              <td>{item.Linea}</td>
              <td>{formatearFecha(item.Fecha)}</td>
              <td>{item.CentroCosto}</td>
              <td>{item.Cuenta}</td>
              <td>{item.Concepto}</td>
              <td>{Intl.NumberFormat("es-419").format(item.Debe)}</td>
              <td>{Intl.NumberFormat("es-419").format(item.Haber)}</td>
              <td>{item.Moneda}</td>
              <td>{item.Nota ? item.Nota : "N/A"}</td>
              <td>{item.Unidad}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <ContextActions
        defaultSearch={false}
        showAll={false}
        customItem={
          <div className="flex-container">
            <button className="btn registers-btn-action" onClick={cargarDatos}>
              Volver a cargar datos
            </button>
            <button
              className="btn registers-btn-action"
              onClick={() =>
                dispatch(obtenerAsientosExcelPorId({ AsientoId: id }))
              }
            >
              Descargar excel
            </button>
            {estado == 1 && (
              <>
                <button
                  className="btn btn-primary registers-btn-action"
                  onClick={onSubmit}
                >
                  Aplicar
                </button>
                <img
                  className="img-spinner-registers"
                  src={spinner}
                  alt="animación enviando datos"
                  hidden={!sendingData}
                />
              </>
            )}
          </div>
        }
      />

      <motion.main
        id="main"
        className="register"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div style={{ width: "100%" }}>
          <Card classN="card" isLoading={isLoading} type={"table"}>
            <h2 className="dark-text table-section-name">
              Detalle del asiento no. {id}
            </h2>
            <input
              className="registers-input superior-right-item"
              type="search"
              placeholder="Búsqueda..."
              onChange={(e) => cargarDatos(e.target.value)}
            />
            {!data?.length > 0 ? (
              <div className="no-results-container">
                <img src={NoResults} alt="Sin resultados"></img>
                <h2 className="grey-text">No se encontraron registros</h2>
              </div>
            ) : (
              <div className="table-container">{renderTable()}</div>
            )}
            <PageActions
              count={totalPages}
              onChange={(value) => displayPage(value)}
              changeRegistersCount={(value) => setRegistrosPorPagina(value)}
              registersCount={registrosPorPagina}
            />
          </Card>
        </div>
      </motion.main>
    </>
  );
}
