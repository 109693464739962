import React from "react";
import { Link } from "react-router-dom";
import logo from "../../IMG/LogoCenfotec.svg";
import Guide from "../Other/Guide";
import ThemeSwitch from "../Other/ThemeSwitch";
import UserMenu from "./UserMenu";

function Header({ toggleMenu, isOpened, darkMode, toggleDarkMode, usuario }) {
  return (
    <header className="flex-container" id="header">
      <div className="toggle-container">
        <button
          className={`btn ${isOpened && "is-hidden-on-mobile"}`}
          id="toggle-btn"
          onClick={toggleMenu}
        >
          <i className="material-icons"></i>
        </button>
        <button
          className={`btn is is-visible-on-mobile ${!isOpened && "is-hidden"}`}
          id="toggle-x-btn"
          onClick={toggleMenu}
        >
          <i className="material-icons"></i>
        </button>
      </div>
      <div className="logo-container is-visible-on-mobile">
        <img src={logo} alt="Logo empresa" />
      </div>
      <h5 className="blue-text is-hidden-on-mobile">Sistema Pase Contable</h5>
      <div className="flex-container is-hidden-on-mobile" id="header-menu">
        <ThemeSwitch />
        <Guide />
        <Link to={"configuraciones"} className="btn grey-text">
          <i className="Material-Icons-Outlined"></i>
        </Link>
        <UserMenu usuario={usuario} />
      </div>
    </header>
  );
}

export default Header;
