import React, { useEffect, useState } from "react";

import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import ContextActions from "../Components/DataBooks/ContextActions";
import Card from "../Components/Other/Card";
import NoResults from "../IMG/NoResults.svg";
import { useParams } from "react-router-dom";
import { obtenerProfesores } from "../Redux/reducers/importacionesSlice";
import { obtenerTiposProfesores } from "../Redux/reducers/genericosSlice";
import {
  actualizarProfesorPrecio,
  almacenarPreciosExcel,
  obtenerPlantillaPreciosExcel,
} from "../Redux/reducers/profesoresPreciosSlice";
import Modal from "../Components/Other/Modal";
import Form from "../Components/Form/Form";
import FormToast from "../Components/Form/FormToast";

function EditableFields({
  item,
  index,
  rowSpan = 1,
  tiposProfesores,
  onChangeField,
  cursosIds,
}) {
  const [precioField, setPrecioField] = useState(item?.CostoTotal);
  const [tipoField, setTipoField] = useState(item?.TipoProfesorId);

  useEffect(() => {
    if (
      precioField !== item?.CostoTotal ||
      tipoField !== item?.TipoProfesorId
    ) {
      if (cursosIds) {
        cursosIds.forEach((curso) => {
          onChangeField(
            {
              CursoProfesorId: curso,
              TipoProfesorId: tipoField,
              Precio: precioField,
            },
            +index + curso
          );
        });
      } else {
        onChangeField(
          {
            CursoProfesorId: item.CursoProfesoresId,
            TipoProfesorId: tipoField,
            Precio: precioField,
          },
          index
        );
      }
    }
  }, [precioField, tipoField]);

  return (
    <>
      <td rowSpan={rowSpan}>
        <select
          value={tipoField}
          onChange={(e) => setTipoField(e.target.value)}
        >
          {tiposProfesores?.map((tipo) => (
            <option key={tipo.TipoProfesorId} value={tipo.TipoProfesorId}>
              {tipo.TipoProfesor}
            </option>
          ))}
        </select>
      </td>
      <td rowSpan={rowSpan}>
        <input
          type="number"
          value={precioField}
          onChange={(e) => setPrecioField(e.target.value)}
        />
      </td>
    </>
  );
}

export default function PreciosProfesores() {
  const [data, setData] = useState();
  const [dataEdit, setDataEdit] = useState([]);
  const [showModalPrecios, setShowModalPrecios] = useState(false);
  const [successCodeExcel, setSuccessCodeExcel] = useState(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const dispatch = useDispatch();
  const { importacionProfesores, isLoadingProfesores } = useSelector(
    (state) => state.importaciones
  );
  const { tiposProfesores } = useSelector((state) => state.genericos);

  const params = useParams();
  const { id, mes, anio } = params;

  const changeData = (obj, index) => {
    let arr = dataEdit;
    let indexFound = dataEdit.indexOf(dataEdit.find((el) => el.index == index));
    if (indexFound === -1) {
      arr.push({ index, obj });
    } else {
      arr[indexFound] = { index, obj };
    }
    setDataEdit(arr);
  };

  const getRowByGroups2 = (item, index) => {
    let seccionesFiltradas = data.filter(
      (p) => p.CursosSecciones === item?.CursosSecciones
    );
    let profesoresFiltrados = data.filter(
      (p) =>
        p.CursosProfesores === item?.CursosProfesores &&
        p.Profesor === item?.Profesor
    );

    let result =
      item.TipoMerge == 1 ? (
        item.CursosSecciones === data[index - 1]?.CursosSecciones ? (
          <tr key={item.CursoProfesoresId}>
            <td>{item.Profesor}</td>
            <EditableFields
              item={item}
              index={index}
              tiposProfesores={tiposProfesores}
              onChangeField={changeData}
            />
          </tr>
        ) : (
          <tr key={item.CursoProfesoresId}>
            <td rowSpan={seccionesFiltradas?.length}>{item.Curso}</td>
            <td rowSpan={seccionesFiltradas?.length}>{item.Seccion}</td>
            <td>{item.Profesor}</td>
            <EditableFields
              item={item}
              index={index}
              tiposProfesores={tiposProfesores}
              onChangeField={changeData}
            />
          </tr>
        )
      ) : item.TipoMerge == 2 ? (
        item.CursosProfesores === data[index - 1]?.CursosProfesores &&
        item.Profesor === data[index - 1]?.Profesor ? (
          <tr key={item.CursoProfesoresId}>
            <td>{item.Curso}</td>
            <td>{item.Seccion}</td>
          </tr>
        ) : (
          <tr key={item.CursoProfesoresId}>
            <td>{item.Curso}</td>
            <td>{item.Seccion}</td>
            <td rowSpan={profesoresFiltrados?.length}>{item.Profesor}</td>
            <EditableFields
              cursosIds={profesoresFiltrados.map((p) => p.CursoProfesoresId)}
              item={item}
              index={index}
              rowSpan={profesoresFiltrados?.length}
              tiposProfesores={tiposProfesores}
              onChangeField={changeData}
            />
          </tr>
        )
      ) : (
        <tr key={item.CursoProfesoresId}>
          <td>{item.Curso}</td>
          <td>{item.Seccion}</td>
          <td>{item.Profesor}</td>
          <EditableFields
            item={item}
            index={index}
            tiposProfesores={tiposProfesores}
            onChangeField={changeData}
          />
        </tr>
      );

    return result;
  };

  useEffect(() => {
    setData(importacionProfesores);
    setDataEdit([]);
  }, [importacionProfesores]);

  useEffect(() => {
    cargarDatos();
    dispatch(obtenerTiposProfesores());
  }, []);

  const cargarDatos = (Buscar = "") => {
    dispatch(obtenerProfesores({ ImportacionId: id, Buscar }));
  };

  const getMonthName = (id) => {
    let fecha = new Date();
    fecha.setMonth(id - 1);
    return new Intl.DateTimeFormat("es-ES", { month: "long" }).format(fecha);
  };

  const descargarPlantillaPrecios = () => {
    dispatch(
      obtenerPlantillaPreciosExcel({
        ano: +anio,
        mes: +mes,
        mesNombre: getMonthName(mes),
      })
    );
  };

  function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
      formData.append(key, object[key]);
    });
    return formData;
  }

  const importarExcel = async (data) => {
    setIsLoadingExcel(true);
    let result;
    delete data.dynamicFieldArray;
    const formD = getFormData(data);
    let { payload } = await dispatch(almacenarPreciosExcel(formD));
    result = payload;

    if (result.success) {
      setData([]);
      cargarDatos();
      setIsLoadingExcel(false);
      setSuccessCodeExcel(1);
      setShowModalPrecios(false);
    } else {
      setIsLoadingExcel(false);
      setSuccessCodeExcel(2);
    }
    setTimeout(() => setSuccessCodeExcel(0), 4000);
  };

  const onSubmit = () => {
    let tempArr = dataEdit;
    let tempArr2 = [];
    tempArr.forEach((el) => {
      delete el.index;
      tempArr2.push(el.obj);
    });
    dispatch(actualizarProfesorPrecio(tempArr2))
      .then((res) => {
        cargarDatos();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const modelForm = {
    Files: {
      label: "Archivo",
      type: "file",
      required: true,
      rules: {
        required: true,
      },
    },
  };

  const renderTable = () => {
    return (
      <table className="table table-profesores">
        <thead>
          <tr>
            <th>Curso</th>
            <th>Sección</th>
            <th>Profesor</th>
            <th>Tipo</th>
            <th>Costo</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => getRowByGroups2(item, index))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <Modal isVisible={showModalPrecios} title="Importar archivo con precios">
        <Form
          loading={isLoadingExcel}
          dynamicForm={modelForm}
          onSubmit={importarExcel}
          onRecoil={() => {
            setShowModalPrecios(false);
          }}
          isEditing={false}
        />
      </Modal>{" "}
      <FormToast
        code={successCodeExcel}
        title={successCodeExcel == 2 ? "¡Algo anda mal!" : "¡Bien!"}
        desc={
          successCodeExcel == 2
            ? "No se pudo subir el archivo."
            : "Los precios se han modificado"
        }
      />
      <ContextActions
        searchFunction={cargarDatos}
        logs="CursosProfesores"
        showAll={false}
        customItem={
          <div className="flex-container">
            <button
              className="btn registers-btn-action"
              onClick={descargarPlantillaPrecios}
            >
              Descargar plantilla
            </button>
            <button
              className="btn registers-btn-action"
              onClick={() => setShowModalPrecios(true)}
            >
              Subir precios
            </button>
            <button
              className="btn btn-primary registers-btn-action"
              onClick={() => onSubmit()}
            >
              Guardar cambios
            </button>
          </div>
        }
      />
      <motion.main
        id="main"
        className="register"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {" "}
        <Card
          classN="card container-col-whole-space"
          isLoading={isLoadingProfesores}
          type={"table"}
        >
          <h2 className="dark-text table-section-name">
            Precios de los profesores
          </h2>
          <h5 className="table-section-subname">
            Importación de {getMonthName(mes)}, {anio}
          </h5>
          {!data?.length > 0 ? (
            <div className="no-results-container">
              <img src={NoResults} alt="Sin resultados"></img>
              <h2 className="grey-text">No se encontraron registros</h2>
            </div>
          ) : (
            <div className="table-container">{renderTable()}</div>
          )}
        </Card>
      </motion.main>
    </>
  );
}
