import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import WebApi from "../../Api/WebApi";

export const obtenerLogs = createAsyncThunk(
  "Logs/obtenerLogs",
  async (tableName, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    const decoded = jwtDecode(token);
    try {
      if (!tableName.includes("Id")) {
        const res = await WebApi.api().post(
          `Logs/GetLogsByTableName?userId=${decoded.id}`,
          { tableName: tableName }
        );
        return res.data.Result;
      } else {
        const res = await WebApi.api().post(
          `Logs/GetLogsByRegistroId?userId=${decoded.id}`,
          { id: tableName }
        );
        return res.data.Result;
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const logsSlice = createSlice({
  name: "logs",
  initialState: {
    logs: [],
    isLoading: false,
    result: { success: false, message: "", show: false },
  },
  extraReducers: {
    [obtenerLogs.pending]: (state, action) => {
      state.isLoading = true;
    },
    [obtenerLogs.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.logs = action.payload;
    },
  },
});

export default logsSlice.reducer;
