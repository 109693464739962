import axios from "axios";
import eventBus from "../services/EventBus";

export default (function WebApi() {
  function api() {
    let token = localStorage.getItem("token");
    if (token == null) {
      return axios.create({
        baseURL: process.env.REACT_APP_BASEURL,
      });
    } else {
      const x = axios.create({
        baseURL: process.env.REACT_APP_BASEURL,
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (token) {
        verifyTokenExpiration(token);
      }
      return x;
    }
  }

  return { api };
})();

function verifyTokenExpiration(token) {
  if (token) {
    let refrToken = JSON.parse(localStorage.getItem("refreshToken"));
    let fechaHoy = Math.floor(new Date().getTime() / 1000.0);
    let decodedToken = JSON.parse(decodeJwt(token));
    let tiempoExpiracion = decodedToken.exp;

    if (tiempoExpiracion - fechaHoy < 360) {
      let data = { refreshToken: refrToken.Token };
      eventBus.dispatch("refresh", data);
    }
  }
}
//   if (tiempoExpiracion - fechaHoy < 120) {
//     let data = { refreshToken: refrToken.Token };
//     axios
//       .post(
//         `${process.env.REACT_APP_BASEURL}NewRefresh`,
//         {
//           ...data,
//         },
//         {
//           headers: {
//             Authorization: "Bearer " + token,
//           },
//         }
//       )
//       .then((res) => {
//         if (res.status == 200) {
//           localStorage.setItem("token", res.data.Result);
//         } else {
//           //log out
//           eventBus.dispatch("logout");
//         }
//       })
//       .catch((err) => {
//         eventBus.dispatch("logout");
//       });
//   }
// }

function decodeJwt(token) {
  return decodeURIComponent(
    window
      .atob(token.split(".")[1].replace("-", "+").replace("_", "/"))
      .split("")
      .map((c) => `%${("00" + c.charCodeAt(0).toString(16)).slice(-2)}`)
      .join("")
  );
}
