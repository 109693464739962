import { useNavigate } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./App.css";
import React, { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { RouteElements } from "./Routes";
import EventBus from "./services/EventBus";
import AuthService from "./services/AuthService";
import { useDispatch } from "react-redux";
import { renovarToken } from "./Redux/reducers/usuariosSlice";
import ModalRemove from "./Components/Other/ModalRemove";
import FormToast from "./Components/Form/FormToast";
import WarningImg from "./IMG/Warning.svg";
import eventBus from "./services/EventBus";
function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [refreshData, setRefreshData] = useState(null);
  const [successCode, setSuccessCode] = useState(0);

  useEffect(() => {
    EventBus.on("logout", () => {
      AuthService.logout(navigate);
    });
    EventBus.on("refresh", (data) => {
      setRefreshData(data);
    });
    // EventBus.on("refresh", (data) => {
    //   dispatch(renovarToken(data))
    // });
  }, []);

  const refrescarSesion = () => {
    dispatch(renovarToken(refreshData)).then(({ payload }) => {
      if (payload.success) {
        setSuccessCode(1);
        localStorage.setItem("token", payload.token);
        setRefreshData(null);
      } else {
        eventBus.dispatch("logout")
        setSuccessCode(2);
        setRefreshData(null);
      }
      setTimeout(() => {
        setSuccessCode(0);
      }, [2000]);
    });
  };

  return (
    <GoogleOAuthProvider clientId="864154840542-quncmpjb66boagsh31o3j6s6eka9guuu.apps.googleusercontent.com">
      <div className="App">
        <FormToast
          code={successCode}
          title={successCode == 2 ? "¡Algo anda mal!" : "¡Bien!"}
          desc={
            successCode == 2
              ? "No se pudo renovar la sesión."
              : "Sesión renovada"
          }
        />
        <ModalRemove
          title="Sesión próxima a vencer"
          message="La sesión esta a punto de expirar. ¿Desea renovarla?"
          onRecoil={() => {
            setRefreshData(null);
            EventBus.dispatch("logout");
          }}
          onSubmit={refrescarSesion}
          removingItem={refreshData}
          useSuccessMessage={false}
          image={WarningImg}
        />
        <AnimatePresence>
          <RouteElements />
        </AnimatePresence>
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
