import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import FormButtons from "../../../Components/Form/FormButtons";
import { FormInput } from "../../../Components/Form/FormElements";
import Modal from "../../../Components/Other/Modal";
import useSubmit from "../../../Hooks/useSubmit";
import { actualizarNivelAcademico } from "../../../Redux/reducers/nivelesAcademicosSlice";

function FormNivelAcademico({
  nivelAcademico,
  isVisible,
  close,
  isEditing,
  reload,
}) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const { onSubmit, sendingData, successCode } = useSubmit(
    isEditing,
    undefined,
    actualizarNivelAcademico,
    null
  );

  useEffect(() => {
    if (successCode.success) {
      reset({});
      close();
      reload();
    }
  }, [successCode]);

  useEffect(() => {
    reset(nivelAcademico);
  }, [nivelAcademico]);

  useEffect(() => {
    let indexHeader, indexMenu, indexNavigation;
    if (isVisible) {
      indexHeader = indexMenu = indexNavigation = 0;
    } else {
      indexHeader = 10;
      indexMenu = 7;
      indexNavigation = 3;
    }
    document
      .getElementById("header")
      .setAttribute("style", `z-index: ${indexHeader}`);
    document
      .getElementById("vertical-menu")
      .setAttribute("style", `z-index: ${indexMenu}`);
    document
      .getElementById("navigation-tab")
      .setAttribute("style", `z-index: ${indexNavigation}`);
  }, [isVisible]);

  const form = {
    Unidad: {
      label: "Unidad",
      type: "number",
      placeholder: "Unidad el nivel académico",
      defaultValue: "",
      required: true,
      rules: {
        required: true,
      },
    },
  };

  const formInputs = Object.keys(form).map((e) => {
    const { rules, defaultValue } = form[e];
    return (
      <section key={e}>
        <Controller
          name={e}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <FormInput
                error={errors[e] && "Este campo es requerido"}
                value={field.value}
                onChange={field.onChange}
                {...form[e]}
              />
            </div>
          )}
        />
      </section>
    );
  });

  return (
    <Modal
      isVisible={isVisible}
      title={
        isEditing
          ? `Edición de nivel académico - ${nivelAcademico.Nombre}`
          : "Nuevo nivel académico"
      }
    >
      <form>
        {formInputs}

        <FormButtons
          onProgress={handleSubmit(onSubmit)}
          progressAction="Guardar"
          loading={sendingData}
          onRecoil={(e) => {
            reset({});
            close(e);
          }}
          recoilAction="Cancelar"
        />
      </form>
    </Modal>
  );
}

export default FormNivelAcademico;
