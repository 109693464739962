import React, { useState } from "react";
import Sidebar from "../Components/Layout/Sidebar";
import Header from "../Components/Layout/Header";
import Breadcrumb from "../Components/Layout/Breadcrumb";
import "../Styles/base.css";
import "../Styles/layout.css";
import "../Styles/modules.css";
import "../Styles/states.css";
import "../Styles/theme.css";
import Loading from "../IMG/Blocks.gif";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import jwtDecode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { obtenerUsuarioPorId } from "../Redux/reducers/usuariosSlice";

function Plantilla({ crumbs }) {
  const [wideMenu, setWideMenu] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const user = jwtDecode(token);
  const { usuario } = useSelector((state) => state.usuarios);
  
  useEffect(() => {
    dispatch(obtenerUsuarioPorId(user.id));
  }, [dispatch, user.id]);

  useEffect(() => {
    setTimeout(() => {
      setPageLoading(false);
    }, 2000);
  }, []);

  return (
    <AnimatePresence>
      {pageLoading && (
        <motion.div
          className="logo-frame"
          id="frameLogo"
          key={1}
          initial={{ opacity: 1 }}
          animate={{ opacity: [1, 1, 1, 0] }}
          transition={{
            duration: 1,
            delay: 3,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <img src={Loading} alt="Cargando" />
        </motion.div>
      )}
      <div id="principal-container">
        <Sidebar
          isOpened={wideMenu}
          toggleMenu={() => setWideMenu(!wideMenu)}
          usuario={usuario}
        />
        <div id="sub-principal-container">
          <Header
            toggleMenu={() => setWideMenu(!wideMenu)}
            isOpened={wideMenu}
            usuario={usuario}
          />
          <Breadcrumb crumbs={crumbs} />
          <Outlet />
        </div>
      </div>
    </AnimatePresence>
  );
}

export default Plantilla;
