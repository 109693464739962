import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import Form from "../../Components/Form/Form";
import FormToast from "../../Components/Form/FormToast";
import useSubmit from "../../Hooks/useSubmit";
import { actualizarContrasenia } from "../../Redux/reducers/usuariosSlice";

function Password() {
  const formRef = useRef();
  const dispatch = useDispatch();
  const { onSubmit, successCode, sendingData } = useSubmit(
    true,
    null,
    actualizarContrasenia
  );

  const settingsForm = {
    OldPassword: {
      label: "Contraseña actual",
      type: "password",
      placeholder: "Contraseña actual",
      defaultValue: "",
      required: true,
      long: true,
      rules: {
        required: true,
      },
    },
    NewPassword: {
      label: "Nueva contraseña",
      type: "password",
      placeholder: "Nueva contraseña",
      defaultValue: "",
      required: true,
      long: true,
      rules: {
        required: true,
      },
    },
    ConfirmNewPassword: {
      label: "Confirme la contraseña",
      type: "password",
      placeholder: "Confirme la contraseña",
      defaultValue: "",
      required: true,
      long: true,
      rules: {
        required: true,
      },
    },
  };

  return (
    <>
     <FormToast
                code={successCode}
                title={successCode == 2 ? "¡Algo anda mal!": "¡Bien!"}
                desc={successCode == 2? "Revisa los datos e intenta nuevamente" : "Contraseña actualizada"}
                />
      <div className="form-title">
        <h2 className="form-title">Cambio de contraseña</h2>
      </div>

      <Form
        ref={formRef}
        dynamicForm={settingsForm}
        onSubmit={onSubmit}
        isEditing={true}
      />
    </>
  );
}

export default Password;
