import React, { useEffect, useRef, useState } from "react";

function PageActions({
  count,
  onChange,
  registersCount,
  changeRegistersCount,
}) {
  const [currentPage, setCurrentPage] = useState(1);

  const pageButtonsScroll = useRef();

  useEffect(() => {
    setCurrentPage(1);
  }, [registersCount]);

  useEffect(() => {
    onChange(currentPage);
    if (count > 6)
      pageButtonsScroll.current = { right: `${currentPage * 35}px` };
  }, [currentPage,onChange]);

  const renderPagesBtn = () => {
    let buttonArray = [];
    for (let i = 1; i <= count; i++) {
      buttonArray?.push(
        <button
          key={i}
          className={currentPage == i ? "btn is-selected" : "btn"}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </button>
      );
    }
    return buttonArray;
  };

  return (
    <div className="flex-container dataTable-bottom">
      <span>
        Mostrando
        <select
          className="dataTable-dropdown"
          onChange={(e) => {
            changeRegistersCount(e.target.value);
          }}
          value={registersCount}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </select>
        registros por página
      </span>
      <div className="flex-container dataTable-pagination">
        <button
          className="btn"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage == 1}
          hidden={currentPage == 1}
        >
          Anteriores
        </button>
        <div className="pagination-numbers-container">
          <div
            className="pagination-numbers-scroll"
            style={pageButtonsScroll.current}
          >
            {renderPagesBtn()}
          </div>
        </div>
        <button
          className="btn"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage == count}
        >
          Siguientes
        </button>
      </div>
    </div>
  );
}

export default PageActions;
