import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";

export const obtenerRoles = createAsyncThunk(
  "Roles/obtenerRoles",
  async (nombre, { rejectWithValue }) => {
    try {
      if (typeof nombre === "undefined" || nombre === "") {
        const res = await WebApi.api().get("Roles");
        return res.data.Result;
      } else {
        const res = await WebApi.api().get(`Roles?q=${nombre}`);
        return res.data.Result;
      }
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
const rolesSlice = createSlice({
    name: "roles",
    initialState: {
        roles: [],
        result: { success: false, message: "", show: false },
    },
    extraReducers: {
       
        [obtenerRoles.fulfilled]: (state, action) => {
            state.roles = action.payload;
        }
    }

})

export default rolesSlice.reducer;