import React, { useEffect, useState } from "react";
import {AnimatePresence, motion} from 'framer-motion';

function Card(props) {
  return (
    <section className={props.classN ? props.classN + " card" : "card"}>
      <AnimatePresence>
      {props.isLoading && (
        <motion.div
          className={"skeleton-parent"}
          initial={{opacity:1}} animate={{opacity:1}}
          transition={{ ease: "linear", duration: 0.5 }}
          exit={{opacity:0}}
        >
          {props.type==="table"?
          (
            <div className="whole-space">
              <div className="skeleton-text"> </div>
              <div className="skeleton-text"> </div>
              <div className="skeleton-text"> </div>
              <div className="skeleton-text"> </div>
              <div className="skeleton-text"> </div>
              <div className="skeleton-text"> </div>
              <div className="skeleton-text"> </div>
              <div className="skeleton-text"> </div>
              <div className="skeleton-text"> </div>
            </div>
          ):props.type==="mosaic"?
          (
            <div className="flex-container mosaic-container whole-space">
            <div className="skeleton-picture"></div>
            <div className="skeleton-picture"></div>
            <div className="skeleton-picture"></div>
            <div className="skeleton-picture"></div>
            <div className="skeleton-picture"></div>
            <div className="skeleton-picture"></div>
            </div>
          ):
          (
            <>
            <div>
            <div className="skeleton-picture"></div>
            <div className="skeleton-picture"></div>
          </div>
          <div>
            <div className="skeleton-text"> </div>
            <div className="skeleton-text"> </div>
            <div className="skeleton-text"> </div>
            <div className="skeleton-text"> </div>
            <div className="skeleton-text"> </div>
            <div className="skeleton-text"> </div>
          </div>
          </>
          )
          }
          
        </motion.div>
      )}
      </AnimatePresence>
      {props.children}
    </section>
  );
}

export default Card;
