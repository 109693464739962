import React from "react";
import spinner from "../../IMG/SpinnerSending.gif";

function FormButtons({onProgress,onRecoil,recoilAction="Cancelar",progressAction="Aceptar", loading=false}) {
  return (
    <div className="form-btn-group">
      <button className="btn alternative-btn" disabled={loading} onClick={onRecoil}>{recoilAction}</button>
      <button
      disabled={loading}
        type="submit"
        className="btn btn-primary"
        onClick={onProgress}
      >
        {progressAction}
      </button>
      <div>
        <img
          src={spinner}
          alt="animación enviando datos"
          hidden={!loading}
        />
      </div>
    </div>
  );
}

export default FormButtons;
