import React,{useState} from "react";
import SettingsMenu from "../../Components/Other/SettingsMenu";
import { motion } from "framer-motion";
import CentroCosto from "./CentrosCostos/CentroCosto";



function Settings() {
    const[activeTab, setActiveTab] = useState(<CentroCosto/>);

    return(
        <motion.main 
        id="main"
        className="register settings-register"
        initial={{opacity:0}}
        animate={{opacity:1}}
        exit={{opacity:0}}
        >
            <div className="settings-content">
            <SettingsMenu  handleEvent={setActiveTab}/>
                {activeTab}
            </div>
   

        </motion.main>
    );
}

export default Settings;