import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoResults from "../../../IMG/NoResults.svg";
import Card from "../../../Components/Other/Card";
import Options from "../../../Components/DataBooks/Options";
import { useNavigate } from "react-router-dom";
import FormToast from "../../../Components/Form/FormToast";
import { obtenerTiposCuentas} from "../../../Redux/reducers/tiposCuentasSlice";
import FormTipoCuenta from "./FormTipoCuenta";

function TipoCuenta() {
  const [formState, setFormState] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [dataEdit, setDataEdit] = useState(undefined);
  const [successCode, setSuccessCode]=useState(0)
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const { tiposCuentas, isLoading } = useSelector((state) => state.tiposCuentas);
  const [toastMessage,setToastMessage]=useState();

  useEffect(() => {
   cargarDatos()
  }, []);

  const cargarDatos=()=>{
    dispatch(obtenerTiposCuentas());
  }

  const setToast=(code,message)=>{
    setSuccessCode(code);
    setToastMessage(message)
    setTimeout(() => {setSuccessCode(0);setToastMessage(null)}, 4000);
  }


  const renderTable = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Código</th>
            <th>Descripción</th>
            <th>CuentaContable</th>
          </tr>
        </thead>
        <tbody>
          {tiposCuentas?.map((item, index) => (
            <tr key={item.TipoId}>
              <td>{item.TipoId}</td>
              <td>{item.Descripcion}</td>
              <td>{item.CuentaContable}</td>
              <td>
                <Options
                  actions={[
                    {
                      icon: <i className="Material-Icons-Outlined"></i>,
                      text: "Editar",
                      onclick: () => {
                          setFormState(true);
                          setIsEditing(true);
                          setDataEdit(item);
                      },
                    },
                    
                  ]}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <FormToast
                code={successCode}
                title={successCode == 2 ? "¡Algo anda mal!": "¡Bien!"}
                desc={toastMessage!==null?toastMessage: successCode == 2? "Revisa los datos e intenta nuevamente" : "Centro de costo almacenado"}
              />

      <FormTipoCuenta
        isEditing={formState && isEditing}
        close={(e) => {
          e?.preventDefault();
          setFormState(false);
          setIsEditing(false);
        }}
        isVisible={formState}
        tipoCuenta={isEditing? dataEdit:{}}
        reload={()=>cargarDatos()}
        setCode={(a)=>setToast(a,null)}
      />
      <motion.main
        id="main"
        className="register"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="form-title">
          <h2 className="form-title">Configuración de tipos de cuentas</h2>
          <button
            className="btn alternative-btn"
            onClick={() => {
              navigate('./logs/TiposCuentas');
            }}
          >
            Cambios realizados
          </button>
          {/* <br/>
          <button
            className="btn btn-primary"
            onClick={() => {setIsEditing(false); setFormState(true);}}
          >
            Nuevo nivel académico
          </button> */}
        </div>
        <Card
          classN="card plantillas-card container-col-whole-space scroll-view"
          isLoading={isLoading}
          type="table"
        >
          {tiposCuentas?.length > 0 ? (
            <div className="table-container">{renderTable()}</div>
          ) : (
            <div className="no-results-container">
              <img src={NoResults} alt="Sin resultados"></img>
              <h2 className="grey-text">No se encontraron registros</h2>
            </div>
          )}
        </Card>
      </motion.main>
    </>
  );
}
export default TipoCuenta;
