import React from "react";
import Modal from "../../Components/Other/Modal";

export default function LogModal(props) {
  return (
    <Modal isVisible={props.isVisible} title="Detalle Log">
      <div className="form-btn-group">
        <button
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            props.toggleVisibility();
          }}
        >
          Aceptar
        </button>
      </div>
    </Modal>
  );
}
