import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";

//AUTENTICACION
export const autenticarUsuario = createAsyncThunk(
  "usuarios/autenticarUsuario",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("GOOGLEAUTH", { credential: item });
      if (res.status == 200)
        return {
          success: true,
          token: res.data.Result.token,
          refreshToken: res.data.Result.refreshToken,
        };
      else
        return {
          success: false,
          message: "Usuario o contraseña incorrectos",
          show: true,
        };
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

export const renovarToken = createAsyncThunk(
  "usuarios/renovarToken",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("NewRefresh", item);
      if (res.status == 200)
        return {
          success: true,
          token: res.data.Result,
        };
      else
        return {
          success: false,
          message: "Error al renovar la sesión",
          show: true,
        };
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

export const validarCorreo = createAsyncThunk(
  "usuarios/validarCorreo",
  async (correo, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("/recover/token", correo);
      if (res.status == 200) return { success: true, message: "Correo valido" };
      else
        return {
          success: false,
          message: "No existe dicho correo",
          show: true,
        };
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

export const recuperarContrasenia = createAsyncThunk(
  "usuarios/recuperarContrasenia",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("recover/recover", data);
      if (res.status == 200)
        return { success: true, message: "Contraseña cambiada correctamente" };
      else
        return {
          success: false,
          message: "No se logro cambiar la contraseña",
          show: true,
        };
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

export const actualizarContrasenia = createAsyncThunk(
  "usuarios/actualizarContrasenia",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("CHANGEPASSWORD", item);
      return { success: true, message: "Contraseña cambiada correctamente" };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//SELECT
export const obtenerUsuariosSelect = createAsyncThunk(
  "usuarios/obtenerUsuariosSelect",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(`Users/getnames`, { iglesiaId: id });
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

//CRUD
export const obtenerUsuarios = createAsyncThunk(
  "usuarios/obtenerUsuarios",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(`Users/GetUsuarios?`, item);
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const obtenerUsuarioPorId = createAsyncThunk(
  "usuarios/obtenerUsuarioPorId",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("users/" + id);
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const agregarUsuario = createAsyncThunk(
  "usuarios/agregarUsuario",
  async (data, { rejectWithValue }) => {
    try {
      delete data.dynamicFieldArray;
      await WebApi.api().post("NewSignUP", data);
      return {
        show: true,
        message: "Campos agregados",
        resultCode: "Success",
        result: [],
      };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const actualizarUsuario = createAsyncThunk(
  "usuarios/actualizarUsuario",
  async (data, { rejectWithValue }) => {
    try {
      delete data.dynamicFieldArray;
      const res = await WebApi.api().put("Users/" + data.UserId, data);
      return {
        show: true,
        message: "Campos actualizados",
        resultCode: "Success",
        result: [],
      };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const eliminarUsuario = createAsyncThunk(
  "usuarios/eliminarUsuario",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put("Users/" + data.UserId, data);
      return {
        show: true,
        message: "Usuario desactivado",
        resultCode: "Success",
        result: [],
      };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const usuariosSlice = createSlice({
  name: "usuarios",
  initialState: {
    usuarios: [],
    isLoading: false,
    usuario: {},
    result: { success: false, message: "", show: false },
  },
  extraReducers: {
    [autenticarUsuario.fulfilled]: (state, action) => {
      state.usuario = action.payload;
    },
    [obtenerUsuarios.pending]: (state, action) => {
      state.isLoading = true;
    },
    [obtenerUsuarios.fulfilled]: (state, action) => {
      state.usuarios = action.payload;
      state.isLoading = false;
    },
    [obtenerUsuarioPorId.fulfilled]: (state, action) => {
      state.usuario = action.payload;
    },
    [obtenerUsuariosSelect.fulfilled]: (state, action) => {
      state.usuarios = action.payload;
    },
  },
});

export default usuariosSlice.reducer;
