import React, { useEffect, useRef, useState } from "react";

import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import ContextActions from "../Components/DataBooks/ContextActions";
import Options from "../Components/DataBooks/Options";
import PageActions from "../Components/DataBooks/PageActions";
import Form from "../Components/Form/Form";
import Card from "../Components/Other/Card";
import Modal from "../Components/Other/Modal";
import usePagination from "../Hooks/usePagination";
import NoResults from "../IMG/NoResults.svg";
import {
  almacenarImportacionDatos,
  obtenerImportacionDatos,
} from "../Redux/reducers/importacionesSlice";
import { useNavigate } from "react-router-dom";
import FormToast from "../Components/Form/FormToast";

export default function CargaDatos() {
  const formRef = useRef();
  const [showModal, setShowModal] = useState();
  const [dataEdit, setDataEdit] = useState({});
  const [successCode, setSuccessCode] = useState({});
  const [data, setData] = useState();
  const [registrosPorPagina, setRegistrosPorPagina] = useState(5);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { importacionDatos, isLoading } = useSelector(
    (state) => state.importaciones
  );

  const anioActual = new Date().getFullYear();
  const [search, setSearch] = useState({ anioActual, mes: 0 });

  const periodos = [
    { periodoId: 1, periodoNombre: "Enero" },
    { periodoId: 2, periodoNombre: "Febrero" },
    { periodoId: 3, periodoNombre: "Marzo" },
    { periodoId: 4, periodoNombre: "Abril" },
    { periodoId: 5, periodoNombre: "Mayo" },
    { periodoId: 6, periodoNombre: "Junio" },
    { periodoId: 7, periodoNombre: "Julio" },
    { periodoId: 8, periodoNombre: "Agosto" },
    { periodoId: 9, periodoNombre: "Septiembre" },
    { periodoId: 10, periodoNombre: "Octubre" },
    { periodoId: 11, periodoNombre: "Noviembre" },
    { periodoId: 12, periodoNombre: "Diciembre" },
  ];

  const navigate = useNavigate();

  const modelForm = {
    Ano: {
      label: "Año",
      type: "number",
      placeholder: "Año",
      required: true,
      rules: {
        required: true,
      },
    },
    Mes: {
      label: "Mes",
      type: "dropdown",
      options: periodos,
      optionIdName: "periodoId",
      optionValueName: "periodoNombre",
      required: true,
      rules: {
        required: true,
      },
    },
  };

  const [
    totalPages,
    startPageIndex,
    endPageIndex,
    currentPageIndex, //ESLINT
    displayPage,
  ] = usePagination(registrosPorPagina, importacionDatos.length);

  useEffect(() => {
    setData(importacionDatos?.slice(startPageIndex, endPageIndex));
  }, [startPageIndex, endPageIndex, importacionDatos]);

  useEffect(() => {
    cargarDatos(search.anioActual, search.mes);
  }, [search]);

  const cargarDatos = (Ano = anioActual, Mes = 0) => {
    dispatch(obtenerImportacionDatos({ Ano, Mes }));
  };

  const editModal = (item) => {
    setDataEdit(item);
    setShowModal(true);
  };

  const onSubmit = (data) => {
    setLoading(true);
    //agrega
    dispatch(almacenarImportacionDatos(data))
      .unwrap()
      .then((res) => {
        navigate(
          `detalleDatos/${res.res.ImportacionId}/${res.res.EstadoId}/${res.res.Mes}/${res.res.Ano}`
        );
      })
      .catch((err) => {
        console.log(err, "error");
        setLoading(false);
        setSuccessCode({
          succes: 2,
          title: "Ha habido un error",
          message: "Intente más tarde",
        });
      });
  };

  const renderTable = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Año</th>
            <th>Mes</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => (
            <tr key={item.ImportacionId}>
              <td>{item.Ano}</td>
              <td>{item.MesNombre}</td>
              <td>{item.Estado}</td>
              <td>
                <Options
                  actions={[
                    {
                      onclick: () =>
                        navigate(
                          `detalleDatos/${item.ImportacionId}/${item.EstadoId}/${item.Mes}/${item.Ano}`
                        ),
                      icon: <i className="Material-Icons-Outlined"></i>,
                      text: "Ver detalle",
                    },
                    {
                      onclick: () =>
                        navigate(
                          `preciosProfesores/${item.ImportacionId}/${item.Mes}/${item.Ano}`
                        ),
                      icon: <i className="Material-Icons-Outlined"></i>,
                      text: "Editar precios",
                    },
                  ]}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <FormToast
        code={successCode?.success}
        title={successCode?.title}
        desc={successCode?.message}
      />
      <Modal
        isVisible={showModal}
        title={dataEdit ? "Editar" : "Importación de datos"}
      >
        <Form
          ref={formRef}
          dynamicForm={modelForm}
          onSubmit={onSubmit}
          onRecoil={() => {
            setShowModal(false);
            setDataEdit({});
          }}
          loading={loading}
          dataToEdit={dataEdit}
          isEditing={false}
          progressAction="Importar"
        />
      </Modal>

      <ContextActions
        onClick={() => {
          editModal(null);
        }}
        btnNew="Añadir"
        defaultSearch={false}
        showAll={false}
        customItem={
          <div className="flex-container">
            <input
              className="registers-input"
              type="number"
              defaultValue={anioActual}
              placeholder="Año"
              onChange={(e) =>
                setSearch({ ...search, anioActual: e.target.value })
              }
            />
            <select
              className="registers-input"
              onChange={(e) => setSearch({ ...search, mes: e.target.value })}
            >
              <option value={0}>Todos los meses</option>
              {periodos?.map((opt) => (
                <option key={opt.periodoId} value={opt.periodoId}>
                  {opt.periodoNombre}
                </option>
              ))}
            </select>
          </div>
        }
      />

      <motion.main
        id="main"
        className="register"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Card
          classN="card container-col-whole-space"
          isLoading={isLoading}
          type={"table"}
        >
          <h2 className="dark-text table-section-name">Carga de datos</h2>
          {!data?.length > 0 ? (
            <div className="no-results-container">
              <img src={NoResults} alt="Sin resultados"></img>
              <h2 className="grey-text">No se encontraron registros</h2>
            </div>
          ) : (
            <div className="table-container">{renderTable()}</div>
          )}
          <PageActions
            count={totalPages} //usuarios.PageCount
            onChange={(value) => displayPage(value)}
            changeRegistersCount={(value) => setRegistrosPorPagina(value)}
            registersCount={registrosPorPagina} //usuarios.RowsCount
          />
        </Card>
      </motion.main>
    </>
  );
}
