import { motion } from "framer-motion";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../Components/Other/Card";
import Docs from "../IMG/Docs.svg";
import InicioImg from "../IMG/General.svg";
import numbers from "../IMG/Generic2.svg";
import datos from "../IMG/Generic.svg";
import { obtenerUsuarioPorId } from "../Redux/reducers/usuariosSlice";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem("token");
  const user = jwtDecode(token);

  const navigate=useNavigate();

  const dispatch = useDispatch();
  const { usuario } = useSelector((state) => state.usuarios);

  useEffect(() => {
    dispatch(obtenerUsuarioPorId(user.id));
    setTimeout(() => setIsLoading(false), 500);
  }, []);

  return (
    <motion.main
      id="main"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="container-col-50">
        <Card classN="flex-container" isLoading={isLoading}>
          <div className="card-img-container">
            <img src={InicioImg} alt="Ilustración Inicio" />
          </div>
          <div>
            <h2 className="dark-text">Hola de nuevo, {usuario.FullName}</h2>
            <p className="dark-text">
              Completa y gestiona de manera segura todas tus actividades
              contables
            </p>
          </div>
        </Card>
        <Card classN="flex-container" isLoading={isLoading}>
          <div className="flex-container error-image-container">
            <img src={Docs} alt="Ilustración: sin datos" />
            <span>sin datos para generar estadístidas</span>
          </div>
        </Card>
      </div>
      <div className="container-col-40">
        <Card isLoading={isLoading}>
          <div className="flex-container error-image-container">
            <div>
              <img src={numbers} alt="Ilustración: estadísticas" />
            </div>
            <div>
              <span>Revisa tus asientos contables</span>
              <hr></hr>
              <button className="btn btn-primary" onClick={()=>navigate("asientos")}>Ver asientos</button>
            </div>
          </div>
        </Card>
        <Card isLoading={isLoading}>
          <div className="flex-container error-image-container">
            <div>
              <img src={datos} alt="Ilustración: sin datos" />
            </div>
            <div>
              <span>Administra los datos financieros importados</span>
              <hr></hr>
              <button className="btn btn-primary" onClick={()=>navigate("cargaDatos")}>Ver importaciones</button>
            </div>
          </div>
        </Card>
      </div>
    </motion.main>
  );
}

export default Dashboard;
