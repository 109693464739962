import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormInput } from "../Components/Form/FormElements";
import FormToast from "../Components/Form/FormToast";
import logo from "../IMG/LogoCenfotec.svg";
import loginImg from "../IMG/Stadistics.svg";
import { validarCorreo } from "../Redux/reducers/usuariosSlice";

export default function ForgotPassword() {
    const {
        register,
        trigger,
        handleSubmit,
        control,
        formState: { errors },
      } = useForm();
    
      const [sendingData, setSendingData] = useState();
      const [successCode, setSuccessCode] = useState(0);
      const navigate = useNavigate();
      const dispatch = useDispatch();
    
      const onSubmit = async (data) => {
        let result;
        let { payload } = await dispatch(validarCorreo(data));
          result = payload;
        if (result.success) {
          setSuccessCode(1);
        } else {
          setSuccessCode(2);
          setTimeout(() => setSuccessCode(0), 3000);
        }
      };
    
      const loginForm = {
        email: {
          label: "Correo",
          type: "text",
          placeholder: "Correo",
          defaultValue: "",
          icon:(<i className='material-icons input-symbol'></i>),
          required: true,
          rules: {
            required: true,
          },
        },
        
      };
    
      const formInputs = Object.keys(loginForm).map((e) => {
        const { rules, defaultValue } = loginForm[e];
        return (
          <section key={e}>
            <Controller
              name={e}
              control={control}
              rules={rules}
              defaultValue={defaultValue}
              render={({ field }) => (
                <div>
                  <FormInput
                    error={errors[e] && "Este campo es requerido"}
                    value={field.value}
                    onChange={field.onChange}
                    {...loginForm[e]}
                  />
                </div>
              )}
            />
          </section>
        );
      });
    
      return (
        <div id="principal-container" className="login-container">
          <main id="main" className="container-col-75">
            <form onSubmit={handleSubmit(onSubmit)}>
            <FormToast
                    code={successCode}
                    title={successCode == 2 ? "¡Algo anda mal!" : "Operacion realizada con exito"}
                    desc={successCode == 2 ? "Dicho correo no existe" : "Token enviado al correo"}
                  />
              <div className="logo-container">
                <img src={logo} alt="Logo empresa" />
              </div>
              <h1 className="grey-text">Recuperar contraseña</h1>
    
              {formInputs}
            
              <input
                type="submit"
                className="btn btn-primary"
                value="Enviar"
              />
            </form>
          </main>
          <div>
            <h3 className="grey-text">SISTEMA PASE CONTABLE</h3>
            <img src={loginImg} alt="Ilustración estadisticas" />
          </div>
        </div>
      );
}
