import React from "react";

function FormToast({code, title, desc}) {

  return (
    <div className={code==1||code==2?"form-toast is-visible":"form-toast"}>
      <div>
        {code==1? <i className="material-icons"></i>:<i className='material-icons is-invalid'></i>}
      </div>
      <div>
        <span className="toast-title">{title}</span>
        <br />
        <span className="toast-message">{desc}</span>
      </div>
    </div>
  );
}

export default FormToast;
