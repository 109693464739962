import React from "react";
import { useNavigate } from "react-router-dom";
import Forbidden from "../IMG/Forbidden.svg";


function Required() {

  const navigate=useNavigate();

  return (
    <div className="error-page">
      <img src={Forbidden} alt="Página no encontrada"></img>
      <h1 className="grey-text">No tienes acceso a este sitio</h1>
        <button className="btn btn-primary" onClick={()=>navigate("/")}>
          Volver a la página principal
        </button>
    </div>
  );
}

export default Required;
