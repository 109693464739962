import React, { useEffect, useRef, useState } from "react";
import FormToast from "../Components/Form/FormToast";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import ContextActions from "../Components/DataBooks/ContextActions";
import Options from "../Components/DataBooks/Options";
import PageActions from "../Components/DataBooks/PageActions";
import Form from "../Components/Form/Form";
import Card from "../Components/Other/Card";
import Modal from "../Components/Other/Modal";
import usePagination from "../Hooks/usePagination";
import NoResults from "../IMG/NoResults.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  obtenerAsientos,
  obtenerAsientosExcel,
  obtenerAsientosExcelAnexos,
} from "../Redux/reducers/asientosSlice";

const periodos = [
  { periodoId: 1, periodoNombre: "Enero" },
  { periodoId: 2, periodoNombre: "Febrero" },
  { periodoId: 3, periodoNombre: "Marzo" },
  { periodoId: 4, periodoNombre: "Abril" },
  { periodoId: 5, periodoNombre: "Mayo" },
  { periodoId: 6, periodoNombre: "Junio" },
  { periodoId: 7, periodoNombre: "Julio" },
  { periodoId: 8, periodoNombre: "Agosto" },
  { periodoId: 9, periodoNombre: "Septiembre" },
  { periodoId: 10, periodoNombre: "Octubre" },
  { periodoId: 11, periodoNombre: "Noviembre" },
  { periodoId: 12, periodoNombre: "Diciembre" },
];

export default function Asientos() {
  const [data, setData] = useState();
  const [registrosPorPagina, setRegistrosPorPagina] = useState(5);
  const dispatch = useDispatch();
  const { asientos, isLoading } = useSelector(
    (state) => state.asientos
  );

  const { anio, mes } = useParams();

  const cargarDatos = () => {
    dispatch(obtenerAsientos({ Ano: anio, mes }));
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  const navigate = useNavigate();

  const [
    totalPages,
    startPageIndex,
    endPageIndex,
    currentPageIndex, //ESLINT
    displayPage,
  ] = usePagination(registrosPorPagina, asientos.length);

  useEffect(() => {
    setData(asientos?.slice(startPageIndex, endPageIndex));
  }, [startPageIndex, endPageIndex, asientos]);

  useEffect(() => {
    if (registrosPorPagina < 5) {
      setRegistrosPorPagina(5);
    }
  }, [registrosPorPagina]);

  const renderTable = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Año</th>
            <th>Mes</th>
            <th>Concepto</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => (
            <tr key={item.AsientoId}>
              <td>{item.Anio}</td>
              <td>{item.Mes}</td>
              <td>{item.Concepto}</td>
              <td>{item.Estado}</td>
              <td>
                <Options
                  actions={[
                    {
                      onclick: () =>
                        navigate(
                          `detalleAsiento/${item.AsientoId}/${item.EstadoId}`
                        ),
                      icon: <i className="Material-Icons-Outlined"></i>,
                      text: "Ver detalle",
                    },
                    {
                      onclick: () =>
                        dispatch(
                          obtenerAsientosExcelAnexos({
                            AsientoId: item.AsientoId,
                          })
                        ),
                      icon: <i className="Material-Icons-Outlined"></i>,
                      text: `Descargar anexos`,
                    },
                  ]}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>

      <ContextActions
        defaultSearch={false}
        showAll={false}
        customItem={
          <div className="flex-container">
            <button
              className="btn registers-btn-action"
              onClick={() => {
                dispatch(
                  obtenerAsientosExcel({
                    Ano: anio,
                    mes: mes,
                  })
                );
              }}
            >
              <i className="Material-Icons-Outlined"></i>
              {"Descargar excel del mes"}
            </button>
          </div>
        }
      />

      <motion.main
        id="main"
        className="register"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Card
          classN="card container-col-whole-space"
          isLoading={isLoading}
          type={"table"}
        >
          <h2 className="dark-text table-section-name">
            Asientos contables del mes de{" "}
            {periodos.find((item) => (item.periodoId = mes)).periodoNombre}
          </h2>
          {!data?.length > 0 ? (
            <div className="no-results-container">
              <img src={NoResults} alt="Sin resultados"></img>
              <h2 className="grey-text">No se encontraron registros</h2>
            </div>
          ) : (
            <div className="table-container">{renderTable()}</div>
          )}
          <PageActions
            count={totalPages} //usuarios.PageCount
            onChange={(value) => displayPage(value)}
            changeRegistersCount={(value) => setRegistrosPorPagina(value)}
            registersCount={registrosPorPagina} //usuarios.RowsCount
          />
        </Card>
      </motion.main>
    </>
  );
}
