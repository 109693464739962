import React from "react";
import Error from "../IMG/Error.svg";

function NoPage() {
  return (
    <div className="error-page">
      <img src={Error} alt="Página no encontrada"></img>
      <h1 className="grey-text">Página no encontrada</h1>
    </div>
  );
}

export default NoPage;
