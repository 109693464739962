import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";

export const obtenerProfesoresPrecios = createAsyncThunk(
  "profesoresPrecios/obtenerProfesoresPrecios",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("profesoresPrecios");
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const obtenerTiposProfesoresPrecios = createAsyncThunk(
  "profesoresPrecios/obtenerTiposProfesoresPrecios",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("profesoresPreciosTipos");
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const actualizarProfesorPrecio = createAsyncThunk(
  "profesoresPrecios/actualizarProfesorPrecio",
  async (item, { rejectWithValue }) => {
    try {
      await WebApi.api().post("CursosProfesores/Actualizar", {
        json: JSON.stringify(item),
      });
      return { success: true, message: "Cambios realizados", show: true };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const almacenarPreciosExcel = createAsyncThunk(
  "profesoresPrecios/almacenarPreciosExcel",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("CURSOSPROFESORESIMPORTAR", item);
      return { success: true, message: "Plantilla almacenada", show: true };
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

export const obtenerPlantillaPreciosExcel = createAsyncThunk(
  "profesoresPrecios/obtenerPlantillaPreciosExcel",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api()
        .post(
          "PlantillaCostoProfesores",
          { ano: item.ano, mes: item.mes },
          { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `Plantilla de costos ${item.mesNombre} ${
            item.ano
          } ${+new Date()}.xlsx`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  profesoresPrecios: [],
  profesorPrecio: {},
  profesoresPreciosTipos: [],
  isLoading: true,
};

export const profesoresPreciosSlice = createSlice({
  name: "profesoresPrecios",
  reducers: {},
  initialState,
  extraReducers: {
    [obtenerProfesoresPrecios.pending]: (state, action) => {
      state.isLoading = true;
    },
    [obtenerProfesoresPrecios.fulfilled]: (state, action) => {
      state.profesoresPrecios = action.payload;
      state.isLoading = false;
    },
    [obtenerTiposProfesoresPrecios.fulfilled]: (state, action) => {
      state.profesoresPreciosTipos = action.payload;
    },
  },
});

export default profesoresPreciosSlice.reducer;
