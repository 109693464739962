import React, { useEffect} from "react";
import { Controller, useForm } from "react-hook-form";
import FormButtons from "../../../Components/Form/FormButtons";
import { FormInput } from "../../../Components/Form/FormElements";
import Modal from "../../../Components/Other/Modal";
import useSubmit from "../../../Hooks/useSubmit";
import {
  actualizarCentroCosto,
  almacenarCentroCosto,
} from "../../../Redux/reducers/centrosCostosSlice";

function FormCentrosCosto({
  centroDatos,
  isVisible,
  close,
  isEditing,
  reload,
}) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const { onSubmit, sendingData, successCode } = useSubmit(
    isEditing,
    almacenarCentroCosto,
    actualizarCentroCosto,
    null
  );

  useEffect(() => {
    if (successCode.success) {
      reset({});
      close();
      reload();
    }
  }, [successCode]);

  useEffect(() => {
    reset(centroDatos);
  }, [centroDatos]);

  useEffect(() => {
    let indexHeader, indexMenu, indexNavigation;
    if (isVisible) {
      indexHeader = indexMenu = indexNavigation = 0;
    } else {
      indexHeader = 10;
      indexMenu = 7;
      indexNavigation = 3;
    }
    document
      .getElementById("header")
      .setAttribute("style", `z-index: ${indexHeader}`);
    document
      .getElementById("vertical-menu")
      .setAttribute("style", `z-index: ${indexMenu}`);
    document
      .getElementById("navigation-tab")
      .setAttribute("style", `z-index: ${indexNavigation}`);
  }, [isVisible]);


  const form = {
   CentroCostoId: {
      label:"Código",
      type: "text",
      placeholder: "Código de centro de costo",
      defaultValue: "",
      required: true,
      rules: {
        required: true,
      },
    },
    Nombre: {
      label: "Nombre",
      type: "text",
      placeholder: "Nombre de centro de costo",
      defaultValue: "",
      required: true,
      rules: {
        required: true,
      },
    },
    Alias: {
      label: "Alias",
      type: "text",
      placeholder: "Alias del centro de costo",
      defaultValue: "",
      required: false,
      rules: {
        required: false,
      },
    },
    Codigo: {
       label:"Código contable",
       type: "text",
       placeholder: "Código contable de centro de costo",
       defaultValue: "",
       required: true,
       rules: {
         required: true,
       },
     },
  };

  const formInputs = Object.keys(form).map((e) => {
    const { rules, defaultValue } = form[e];
    return (
      <section key={e}>
        <Controller
          name={e}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <FormInput
                error={errors[e] && "Este campo es requerido"}
                value={field.value}
                onChange={field.onChange}
                {...form[e]}
              />
            </div>
          )}
        />
      </section>
    );
  });

  return (
    <Modal
      isVisible={isVisible}
      title={isEditing ? "Edición de centro de costo" : "Nuevo centro de costo"}
    >
      <form>
        {formInputs}

        <FormButtons
          onProgress={handleSubmit(onSubmit)}
          progressAction="Guardar"
          loading={sendingData}
          onRecoil={(e) => {
            reset({});
            close(e);
          }}
          recoilAction="Cancelar"
        />
      </form>
    </Modal>
  );
}

export default FormCentrosCosto;
