import React, { useEffect, useRef, useState } from "react";
import useComponentVisible from "../../Hooks/useComponentVisible";

function Options({ actions }) {
  const btnContanier = useRef(null);
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  useEffect(() => {
    if (isComponentVisible) {
      btnContanier.current.style.left = pos.x - 100 + "px";
      btnContanier.current.style.top = pos.y + 10 + "px";
    }
  }, [isComponentVisible]);

  const handleClick = (e) => {
    e.preventDefault();
    if (isInViewport({ x: e.clientX, y: e.clientY })) {
      setPos({ x: e.clientX-80, y: e.clientY - btnContanier.current.clientHeight});
    } else {
      setPos({ x: e.clientX, y: e.clientY });
    }
    setIsComponentVisible(!isComponentVisible);
  };

  function isInViewport(distance) {
    return (
      distance.y+ btnContanier.current.clientHeight >
      (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  return (
    <>
      <button
        ref={ref}
        className={
          isComponentVisible == true ? "btn is-showing-options" : "btn"
        }
        onClick={handleClick}
      >
        <i className="material-icons"></i>
      </button>
      <div className="register-options-container" ref={btnContanier}>
        {actions?.map((action, index) => (
          <button className="btn" onClick={action.onclick} key={index}>
            {action.icon} {action.text}
          </button>
        ))}
      </div>
    </>
  );
}

export default Options;
