import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WebApi from "../../Api/WebApi";

export const obtenerPlantillas = createAsyncThunk(
  "plantillas/obtenerPlantillas",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("plantillas");
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const obtenerTiposPlantillas = createAsyncThunk(
  "plantillas/obtenerTiposPlantillas",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("plantillasTipos");
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const obtenerPlantillaById = createAsyncThunk(
  "plantillas/obtenerPlantillaById",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("Plantillas/"+id);
      return res.data.Result;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const actualizarPlantilla = createAsyncThunk(
  "plantillas/actualizarPlantilla",
  async (item, { rejectWithValue }) => {
    try {
      delete item["ModificadoPor"];
      delete item["FechaModificacion"];
      let id=item.PlantillaId;
      delete item["PlantillaId"]
      const res = await WebApi.api().put("Plantillas/"+id,item);
      return { success: true, message: "Cambios realizados", show: true };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);


export const almacenarPlantilla= createAsyncThunk(
  "plantillas/almacenarPlantilla",
  async (item, { rejectWithValue }) => {
    try {
      let obj={...item,EstadoId:1,EsTextoPlano:false}
      const res = await WebApi.api().post("Plantillas",obj);
      return { success: true, message: "Plantilla almacenada", show: true };
    } catch (err) {
        return { success: false, message: err, show: true };
    }
  }
);



const initialState = {
  plantillas: [],
  plantilla:{},
  plantillasTipos:[],
  isLoading:true,
};

export const plantillasSlice = createSlice({
  name: "plantillas",
  reducers: {},
  initialState,
  extraReducers: {
    [obtenerPlantillas.fulfilled]: (state, action) => {
      state.plantillas = action.payload;
      state.isLoading=false;
    },
    [obtenerPlantillaById.fulfilled]: (state, action) => {
      state.plantilla = action.payload;
    },
    [obtenerTiposPlantillas.fulfilled]: (state, action) => {
      state.plantillasTipos = action.payload;
    },
  },
});

export default plantillasSlice.reducer;
