import React, { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormToast from "../Components/Form/FormToast";
import loginImg from "../IMG/Stadistics.svg";
import logo from "../IMG/LogoCenfotec.svg";
import { autenticarUsuario } from "../Redux/reducers/usuariosSlice";

function Login() {
  const {
    formState: { errors },
  } = useForm();

  const [sendingData, setSendingData] = useState();
  const [successCode, setSuccessCode] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const handleCallbackResponse = async (response) => {
    setSendingData(true);
    let result;
    let {payload} = await dispatch(autenticarUsuario(response.credential));
    result=payload;
    if (result.success) {
      localStorage.setItem("token", result.token);
      localStorage.setItem("refreshToken", JSON.stringify(result.refreshToken));
      navigate("/");
    } else {
      setSuccessCode(2);
      setSendingData(false);
      setTimeout(() => setSuccessCode(0), 3000);
    }
  };

  return (
    <div id="principal-container" className="login-container">
      <main id="main" className="container-col-75">
        <form>
          <FormToast
            code={successCode}
            title={successCode == 2 && "¡Algo anda mal!"}
            desc={
              successCode == 2 && "Revisa tus credenciales e intenta de nuevo."
            }
          />
          <br />
          <br />
          <div className="logo-container">
            <img src={logo} alt="Logo empresa" />
          </div>
          <h3 className="grey-text">Sistema Pase Contable</h3>
          <h1 className="form-title">Inicio de sesión</h1>

          <GoogleLogin
            onSuccess={(credentialResponse) => {
              handleCallbackResponse(credentialResponse);
            }}
            onError={() => {
              console.log("Login Failed");
            }}
          />
        </form>
      </main>
      <div>
        <img src={loginImg} alt="Ilustración estadisticas" />
      </div>
    </div>
  );
}

export default Login;
